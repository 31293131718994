import * as React from "react";
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';
import Typography from '@material-ui/core/Typography';

const TimeWithZoneField = (props) => {
    const { source, includeDate } = props;
    const record = useRecordContext(props);

    const dateValue = record[source];
    
    if (dateValue === null || dateValue === '') return null;

    const date = new Date(dateValue);

    const datePart = includeDate ? date.toLocaleString("en-AU", { weekday: 'short', day: 'numeric', month: 'long' }) + ', ' : '';
    const timePart = date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' }) + " +" + (record[source].toString().endsWith('Z') ? '00:00' : record[source].toString().split('+')[1]);

    return <Typography component="span" variant="body2">{datePart}{timePart}</Typography>;
}  

TimeWithZoneField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

TimeWithZoneField.defaultProps = {
    addLabel: true,
    sortable: false
};

export default TimeWithZoneField;