import * as React from "react";
import { 
    List, Show, Edit, Create, 
    Datagrid, Filter, SimpleList, SimpleShowLayout, SimpleForm, Pagination,
    TextField, DateField, BooleanField,
    TextInput, DateInput, BooleanInput, RadioButtonGroupInput,
    required, useNotify, useRedirect, useShowController
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LogoField from '../components/LogoField';
import DateTimeField from '../components/DateTimeField';

const useStyles = makeStyles(
    {
        tertiary: {
            opacity: 1
        }
    },
    {
        name: 'RaSimpleList'
    }
);

const CoachFilter = props => (
    <Filter {...props}>
        <RadioButtonGroupInput label="Status" source="isActive" choices={[
            { id: null, name: 'All' },
            { id: true, name: 'Active' },
            { id: false, name: 'Inactive' }
        ]} alwaysOn />
    </Filter>
);

export const CoachList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const classes = useStyles(props);

    const ListPagination = (props) => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

    return (
        <List {...props} bulkActionButtons={false} filters={<CoachFilter />} filterDefaultValues={{ isActive: null }} pagination={<ListPagination />} perPage={10}>
            {isSmall ? (
                <SimpleList classes={classes}
                    primaryText={record => `${record.lastName}, ${record.firstName}`}
                    secondaryText={record => record.age ? record.age : 'Unknown DOB'}
                    tertiaryText={record => <LogoField type="coaches" source="id" extension="png" fallback="coach.png" height={42} />}
                    linkType="show"
                />
            ) : (
                <Datagrid rowClick="show">
                    <LogoField type="coaches" source="id" text="firstName" extension="png" fallback="coach.png" height={48} width={58} label="First Name" />
                    <TextField source="lastName" label="Last Name" />
                    <DateField source="dob" label="Date Of Birth" options={{ day: 'numeric', month: 'long', year: 'numeric' }} locales="en-AU" />
                    <TextField source="age" label="Age" />
                    <BooleanField source="isActive" label="Active?" />
                </Datagrid>
            )}
        </List>
    );
};
export const CoachShow = props => {
    const { loading } = useShowController(props);
    if (loading) return null;

    return (
        <Show {...props}>
            <SimpleShowLayout>
                <LogoField type="coaches" source="id" extension="png" fallback="coach.png" height={192} addLabel={false} />
                <TextField source="id" label="ID" />
                <TextField source="firstName" label="First Name" />
                <TextField source="lastName" label="Last Name" />
                <DateField source="dob" label="Date Of Birth" options={{ day: 'numeric', month: 'long', year: 'numeric' }} locales="en-AU" />
                <BooleanField source="isActive" label="Active?" />
                <DateTimeField source="createdUtc" label="Created" />
                <DateTimeField source="lastUpdatedUtc" label="Last Updated" />
            </SimpleShowLayout>
        </Show>
    );
};

export const CoachEdit = props => (
    <Edit {...props} mutationMode="optimistic">
        <SimpleForm>
            <TextInput source="id" label="ID" fullWidth={true} disabled />
            <TextInput source="firstName" label="First Name" fullWidth={true} validate={[required()]} />
            <TextInput source="lastName" label="Last Name" fullWidth={true} validate={[required()]} />
            <DateInput source="dob" label="Date of Birth" />
            <BooleanInput source="isActive" label="Active?" validate={[required()]} />
        </SimpleForm>
    </Edit>
);

export const CoachCreate = props => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = ({ data }) => {
		notify(`Coach '${data.firstName} ${data.lastName}' successfully created.`);
		redirect(`/coach/${data.id}/show`);
	};

    return (
        <Create onSuccess={onSuccess} {...props}>
            <SimpleForm>
                <TextInput source="firstName" label="First Name" fullWidth={true} validate={[required()]} />
                <TextInput source="lastName" label="Last Name" fullWidth={true} validate={[required()]} />
                <DateInput source="dob" label="Date of Birth" />
            </SimpleForm>
        </Create>
    );
};