import * as React from "react";
import { 
    List, Show, Edit, Create, 
    Datagrid, SimpleShowLayout, SimpleForm, Toolbar, TopToolbar,
    TextField, ArrayField, SingleFieldList, ReferenceField, FunctionField,
    ReferenceInput, SelectInput, SelectArrayInput, NumberInput, TextInput, BooleanInput,
    EditButton, DeleteButton, SaveButton, ShowButton, ListButton,
    required, useNotify, useRedirect, useShowController, useRecordContext, useGetList
} from 'react-admin';
import { useFormState } from 'react-final-form';
import Config from "../providers/configProvider";
import LogoField from '../components/LogoField';
import DateTimeField from '../components/DateTimeField';

import AddRoundIcon from '@material-ui/icons/Add';

const UpdateToolbar = (props) => (
    <Toolbar {...props}>
        <SaveButton
            label="Save"
            redirect="show"
            submitOnEnter={true}
        />
        <div style={{flex:1}}></div>
        <ShowButton
            label="Show"
            basePath="/competition-season"
        />
    </Toolbar>
);

export const CompetitionSeasonList = props => (
    <List {...props} bulkActionButtons={false}>
        <Datagrid rowClick="show">
            <LogoField type="competitions" source="competitionCode" text="competitionCode" label="Competition" />
            <TextField source="seasonYear" label="Season" />
            <TextField source="name" label="Name" />
        </Datagrid>
    </List>
);

export const CompetitionSeasonShow = props => {
    const { basePath, ...rest } = props;
    const { loading } = useShowController(props);
    if (loading) return null;

    const Actions = ({ data }) => (
        <TopToolbar {...rest}>
            <ListButton
                label="Competition Season List"
                basePath={basePath}
            />
            <EditButton
                label="Edit Competition Season"
                basePath={basePath}
                record={data} />
            <EditButton
                label="Add Round"
                icon={<AddRoundIcon />}
                basePath="/rounds"
                record={data} />
            <DeleteButton 
                label="Delete"
                basePath={basePath}
                record={data}
                mutationMode="optimistic"
                confirmTitle="Are you sure you want to delete this competition season?"
                confirmContent="This action cannot be undone." />
        </TopToolbar>
    );

    const Title = ({ record }) => <span>{record.competition.name} {record.season.year}</span>;

    return (
        <Show title={<Title />} actions={<Actions />} {...props}>
            <SimpleShowLayout>
                <TextField source="id" label="ID" />
                <TextField source="name" label="Name" />
                <TextField source="competition.name" label="Competition" />
                <TextField source="season.year" label="Season" />
                <FunctionField label="Rounds" render={record => (
                    <ArrayField source="rounds">
                        <Datagrid>
                            <ReferenceField label="Match Type" source="matchTypeId" reference="match-type" link={false}>
                                <TextField source="name" />
                            </ReferenceField>
                            <TextField source="number" label="Number" />
                            <TextField source="name" label="Name" />
                                <ArrayField source="byes" label="Byes">
                                    <SingleFieldList linkType={false}>
                                        <LogoField type="teams" source="code" text={record.competition.code === 'NRL' ? 'nickname' : 'name'} additionalText="&nbsp;" />
                                    </SingleFieldList>
                                </ArrayField>
                        </Datagrid>
                    </ArrayField>
                )} />
                <FunctionField label="Teams" render={record => (
                    <ArrayField source="teams">
                        <Datagrid>
                            <ReferenceField label="Team" source="id" reference="team" link={false}>   
                                <LogoField type="teams" source="code" text={record.competition.code === 'NRL' ? 'nickname' : 'name'} />
                            </ReferenceField>
                            <TextField source="penaltyPoints" label="Penalty Points" />
                            <TextField source="penaltyFor" label="Penalty For" />
                            <TextField source="penaltyAgainst" label="Penalty Against" />
                        </Datagrid>
                    </ArrayField>
                )} />
                <TextField source="teamsInFinals" label="Teams In Finals" />
                <TextField source="pointsForWin" label="Points For Win" />
                <TextField source="pointsForDraw" label="Points For Draw" />
                <TextField source="pointsForBye" label="Points For Bye" />
                <ArrayField source="scoreTypes" label="Score Types">
                    <Datagrid>
                        <ReferenceField label="Score Type" source="id" reference="score-type" link={false}>
                            <TextField source="name" />
                        </ReferenceField>
                        <TextField source="points" label="Points" />
                    </Datagrid>
                </ArrayField>
                <DateTimeField source="createdUtc" label="Created" />
                <DateTimeField source="lastUpdatedUtc" label="Last Updated" />
            </SimpleShowLayout>
        </Show>
    );
}

export const CompetitionSeasonEdit = props => (
    <Edit {...props} mutationMode="optimistic">
        <SimpleForm>
            <TextInput source="id" label="ID" fullWidth={true} disabled />
            <TextInput source="name" label="Name" fullWidth={true} validate={[required()]} />
        </SimpleForm>
    </Edit>
);

export const CompetitionSeasonRoundAdd = props => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = ({ data }) => {
		notify(`Round successfully added.`);
		redirect(`/competition-season/${data.id}/show`);
	};

    const ByeTeamSelectInput = () => {
        const record = useRecordContext();
        const { data, loading } = useGetList('team', { page: 1, perPage: 1000 });
        if (loading) return <span>Loading</span>;

        const teams = record.teams.map(t => data[t.id]);

        if (record.competition.code === 'NRL')
            teams.sort((a,b) => a.code === 'TBA' ? 2 : ((a.nickname > b.nickname) ? 1 : ((b.nickname > a.nickname) ? -1 : 0)));
        else
            teams.sort((a,b) => a.code === 'TBA' ? 2 : ((a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));

        return (
            <SelectArrayInput label="Byes" choices={teams} optionText={<LogoField type="teams" source="code" text={record.competition.code === 'NRL' ? 'nickname' : 'name'} />} source="addRound.byeTeamIds" perPage={1000} fullWidth={true} allowEmpty />
        );
    };

    const Title = ({ record }) => <span>Add Round - {record.competition.name} {record.season.year}</span>;

    return (
        <Edit title={<Title />} onSuccess={onSuccess} mutationMode="pessimistic" {...props}>
            <SimpleForm toolbar={<UpdateToolbar />}>
                <TextField label="ID" source="id" />
                <LogoField type="competitions" source="competition.code" text="competition.name" label="Competition" />
                <TextField label="Season" source="season.year" />
                <ReferenceInput label="Match Type" source="addRound.matchTypeId" reference="match-type" perPage={1000} fullWidth={true} validate={[required()]}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <NumberInput source="addRound.number" label="Number" min={1} max={99} step={1} fullWidth={true} validate={[required()]} />
                <TextInput source="addRound.Name" label="Name" fullWidth={true} validate={[required()]} />
                <ByeTeamSelectInput />
            </SimpleForm>
        </Edit>
    );
};

export const CompetitionSeasonCreate = props => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = ({ data }) => {
		notify(`Competition Season '${data.name}' successfully created.`);
		redirect(`/competition-season/${data.id}/show`);
	};

    const TeamSelectInput = ({ label, source }) => {
        const { values } = useFormState();
        const { data, loading } = useGetList('team', { page: 1, perPage: 1000 });
        if (loading) return <span>Loading</span>;

        const teams = Object.keys(data).map(key => data[key]);

        if (values.competitionId === Config.NRLCompetitionId)
            teams.sort((a,b) => a.code === 'TBA' ? 2 : ((a.nickname > b.nickname) ? 1 : ((b.nickname > a.nickname) ? -1 : 0)));
        else
            teams.sort((a,b) => a.code === 'TBA' ? 2 : ((a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));

        return (
            <SelectArrayInput label={label} source={source} choices={teams} optionText={<LogoField type="teams" source="code" text={values.competitionId === Config.NRLCompetitionId ? 'nickname' : 'name'} />} perPage={1000} fullWidth={true} validate={[required()]} />
        )
    };

    return (
        <Create onSuccess={onSuccess} {...props}>
            <SimpleForm>
                <TextInput label="Name" source="name" fullWidth={true} validate={[required()]} />
                <ReferenceInput label="Competition" source="competitionId" reference="competition" perPage={100} fullWidth={true} validate={[required()]}>
                    <SelectInput optionText={<LogoField type="competitions" source="code" text="name" />} />
                </ReferenceInput>
                <ReferenceInput label="Season" source="seasonId" reference="season" perPage={100} fullWidth={true} validate={[required()]}>
                    <SelectInput optionText="year" />
                </ReferenceInput>
                <TeamSelectInput label="Teams" source="teamIds" />
                <NumberInput label="Points for Wins" source="pointsForWin" min={1} max={99} step={1} defaultValue={2} validate={[required()]} />
                <NumberInput label="Points for Draws" source="pointsForDraw" min={1} max={99} step={1} defaultValue={1} validate={[required()]} />
                <NumberInput label="Points for Byes" source="pointsForBye" min={1} max={99} defaultValue={2} step={1} validate={[required()]} />
                <NumberInput label="Teams In Finals" source="teamsInFinals" min={1} max={99} step={1} validate={[required()]} />
                <NumberInput label="Points for Tries" source="pointsForTries" min={1} max={99} step={1} defaultValue={4} validate={[required()]} />
                <NumberInput label="Points for Goals" source="pointsForGoals" min={1} max={99} step={1} defaultValue={2} validate={[required()]} />
                <NumberInput label="Points for Field Goals" source="pointsForFieldGoals1" min={1} max={99} defaultValue={1} step={1} validate={[required()]} />
                <NumberInput label="Points for 40m Field Goals" source="pointsForFieldGoals2" min={1} max={99} defaultValue={2} step={1} validate={[required()]} />
                <BooleanInput label="Sin Bins?" source="hasSinBins" defaultValue={true} validate={[required()]} />
                <BooleanInput label="Send Offs?" source="hasSendOffs" defaultValue={true} validate={[required()]} />
            </SimpleForm>
        </Create>
    );
};