import * as React from "react";
import { 
    List,
    Datagrid, Filter,
    TextField, FunctionField,
    ReferenceInput, SelectInput, NumberInput, RadioButtonGroupInput, useRecordContext
} from 'react-admin';
import { TableHead, TableRow, TableCell, useMediaQuery, makeStyles } from '@material-ui/core';
import Config from "../providers/configProvider";
import LogoField from '../components/LogoField';

const ListRowStyle = (record, index) => ({
    backgroundColor: Config.MyTeamCodes.includes(record.teamCode) ? '#efe' : '#fff',
    borderTopStyle: record.hasPools && record.position === 1 && index !== 0 ? 'double' : 'unset',
    borderBottomStyle: record.position === record.teamsInFinals ? 'double' : 'unset',
    textTransform: record.position <= record.teamsInFinals ? 'uppercase': 'unset'
});

const LadderFilter = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <Filter {...props} style={{marginTop: isSmall ? '0px' : '-16px'}}>
            <ReferenceInput label="Competition" source="competitionId" reference="competition" filter={{ competitionIds: Config.PoolCompetitionIds.concat([Config.NRLCompetitionId]) }} perPage={1000} alwaysOn>
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput label="Season" source="seasonId" reference="season" perPage={1000} alwaysOn>
                <SelectInput optionText="year" />
            </ReferenceInput>
            <NumberInput label="Start Round" source="startRound" min={1} max={99} step={1} style={{width:120}} alwaysOn={!isSmall} />
            <NumberInput label="End Round" source="endRound" min={1} max={99} step={1} style={{width:120}} alwaysOn={!isSmall} />
            <RadioButtonGroupInput label="Live Ladder?" source="showLive" choices={[
                { id: true, name: 'Yes' },
                { id: false, name: 'No' }
            ]} alwaysOn />
        </Filter>
    );
};

const useStyles = makeStyles({
    cellSmall: {
        padding: '8px'
    },
    cell: {
        textAlign: 'right'
    },
    cellLeft: {
        textAlign: 'left'
    },
    cellBorder: {
        borderLeft: '1px solid #000',
        textAlign: 'right'
    },
    topHeader: {
        fontWeight: 'bold',
        textAlign: 'center'
    },
    topHeaderBorder: {
        fontWeight: 'bold',
        textAlign: 'center',
        borderLeft: '1px solid #000'
    }
});

const LadderHeader = ({ children }) => {
    const classes = useStyles();
    return (
        <TableHead>
            <TableRow>
                <TableCell colSpan={2} />
                <TableCell colSpan={10} className={classes.topHeader}>Overall</TableCell>
                <TableCell colSpan={8} className={classes.topHeaderBorder}>Home</TableCell>
                <TableCell colSpan={8} className={classes.topHeaderBorder}>Away</TableCell>
            </TableRow>
            <TableRow>
                {React.Children.map(children, child => (
                    <TableCell key={child.props.source} className={child.props.source === 'home.played' || child.props.source === 'away.played' ? classes.cellBorder : (child.props.label === 'Team' || child.props.label === 'Pos' || child.props.label === 'Pool' ? classes.cellLeft : classes.cell)}>
                        {child.props.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

export const LadderList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const classes = useStyles();
    
    const PositionTextField = ({ label, source, cellClassName }) => {
        const record = useRecordContext();

        if (record.hasPools)
            return <FunctionField render={record => `${record.pool}-${record[source]}`} label={label} cellClassName={cellClassName} />;
    
        return <TextField source={source} label={label} cellClassName={cellClassName} />;
    };

    return (
        <List {...props} bulkActionButtons={false} filters={<LadderFilter />} filterDefaultValues={{ competitionId: Config.NRLCompetitionId, seasonId: Config.CurrentSeasonId, showLive: false }} pagination={null}>
            {isSmall ? (
                <Datagrid classes={{ rowCell: classes.cellSmall, headerCell: classes.cellSmall }} rowStyle={ListRowStyle}>
                    <PositionTextField source="position" label="Pos" /> 
                    <LogoField type="teams" source="teamCode" text="teamCode" label="Team" />
                    <TextField source="overall.played" label="P" />                    
                    <TextField source="overall.won" label="W" />
                    <TextField source="overall.drawn" label="D" />
                    <TextField source="overall.lost" label="L" />
                    <TextField source="byes" label="B" />
                    <TextField source="overall.pointsDiff" label="+/-" />
                    <TextField source="competitionPoints" label="Pts" style={{fontWeight:'bold'}} />
                </Datagrid>
            ) : (
                <Datagrid header={<LadderHeader />} classes={{ rowCell: classes.cell, headerCell: classes.cell }} rowStyle={ListRowStyle}>
                    <PositionTextField source="position" label="Pos" cellClassName={classes.cellLeft} />
                    <FunctionField render={record => <LogoField type="teams" source="teamCode" text={record.competitionCode === "NRL" ? "teamNickname" : "teamName"} />} label="Team" cellClassName={classes.cellLeft} />
                    <TextField source="overall.played" label="P" />
                    <TextField source="overall.won" label="W" />
                    <TextField source="overall.drawn" label="D" />
                    <TextField source="overall.lost" label="L" />
                    <TextField source="byes" label="B" />
                    <TextField source="overall.pointsFor" label="F" />
                    <TextField source="overall.pointsAgainst" label="A" />
                    <TextField source="overall.pointsDiff" label="+/-" />
                    <TextField source="overall.winPercentage" label="W%" />
                    <TextField source="competitionPoints" label="Pts" style={{fontWeight:'bold'}} />
                    <TextField source="home.played" label="P" cellClassName={classes.cellBorder} />
                    <TextField source="home.won" label="W" />
                    <TextField source="home.drawn" label="D" />
                    <TextField source="home.lost" label="L" />
                    <TextField source="home.pointsFor" label="F" />
                    <TextField source="home.pointsAgainst" label="A" />
                    <TextField source="home.pointsDiff" label="+/-" />
                    <TextField source="home.winPercentage" label="W%" />
                    <TextField source="away.played" label="P" cellClassName={classes.cellBorder} />
                    <TextField source="away.won" label="W" />
                    <TextField source="away.drawn" label="D" />
                    <TextField source="away.lost" label="L" />
                    <TextField source="away.pointsFor" label="F" />
                    <TextField source="away.pointsAgainst" label="A" />
                    <TextField source="away.pointsDiff" label="+/-" />
                    <TextField source="away.winPercentage" label="W%" />
                </Datagrid>
            )}
        </List>
    );
};
