import * as React from "react";
import { 
    List, Show,
    Datagrid, Filter, TopToolbar, SortButton, ExportButton, SimpleShowLayout,
    TextField, NumberField, DateField, FunctionField, ArrayField,
    ReferenceInput, SelectInput, RadioButtonGroupInput,
    useRecordContext, useListContext, useListSortContext, useShowController
} from 'react-admin';
import { Table, TableHead, TableBody, TableRow, TableCell, useMediaQuery, makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import get from 'lodash/get';
import Config from "../providers/configProvider";
import LogoField from '../components/LogoField';
import TimeWithZoneField from '../components/TimeWithZoneField';

const CompetitionSeasonFilter = props => (
    <Filter {...props}>
        <ReferenceInput label="Comp Season" source="competitionSeasonId" reference="competition-season" perPage={1000} alwaysOn allowEmpty={false}>
            <SelectInput optionText={record => `${record.name} ${record.seasonYear}`} />
        </ReferenceInput>
    </Filter>
);

const CompetitionSeasonAndTeamFilter = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <Filter {...props} style={{marginTop: isSmall ? '0px' : '-16px'}}>
            <ReferenceInput label="Comp Season" source="competitionSeasonId" reference="competition-season" filter={{ competitionCode: 'NRL' }} perPage={1000} alwaysOn>
                <SelectInput optionText={record => `${record.name} ${record.seasonYear}`} />
            </ReferenceInput>
            <ReferenceInput label="Team" source="teamId" reference="team" perPage={1000} alwaysOn>
                <SelectInput optionText={<LogoField type="teams" source="code" text="name" />} disabled={!props.enableTeam} />
            </ReferenceInput>
        </Filter>
    );
};

const ListActions = ({ sortFields }) => (
    <TopToolbar>
        <SortButton fields={sortFields} />
        <ExportButton />
    </TopToolbar>
);

const useStyles = makeStyles({
    cellSmall: {
        padding: '8px'
    },
    cell: {
        textAlign: 'right'
    },
    cellLeft: {
        textAlign: 'left'
    },
    cellBorder: {
        borderLeft: '1px solid #000',
        textAlign: 'right'
    },
    cellBorderLeft: {
        borderLeft: '1px solid #000',
        textAlign: 'left'
    },
    cellBorderDouble: {
        borderLeft: '3px double #000',
        textAlign: 'right'
    },
    topHeader: {
        fontWeight: 'bold',
        textAlign: 'center'
    },
    topHeaderBorder: {
        fontWeight: 'bold',
        textAlign: 'center',
        borderLeft: '1px solid #000'
    },
    topHeaderBorderDouble: {
        fontWeight: 'bold',
        textAlign: 'center',
        borderLeft: '3px double #000'
    },
    green: {
        fontWeight: 'bold',
        color: 'green'
    },
    greenSmall: {
        color: 'green'
    },
    red: {
        fontWeight: 'bold',
        color: 'red'
    },
    redSmall: {
        color: 'red'
    }
});

/*** TEAM STATS ***/
const teamStatsSortFields = [
    { field: 'points.for', label: 'points for' }, 
    { field: 'points.against', label: 'points against' }, 
    { field: 'points.diff', label: 'points diff' }, 
    { field: 'tries.for', label: 'tries for' }, 
    { field: 'tries.against', label: 'tries against' }, 
    { field: 'tries.diff', label: 'tries diff' }, 
    { field: 'goals.for', label: 'goals for' }, 
    { field: 'goals.against', label: 'goals against' }, 
    { field: 'goals.diff', label: 'goals diff' }, 
    { field: 'fieldGoals1.for', label: '1 point field goals for' }, 
    { field: 'fieldGoals1.against', label: '1 point field goals against' }, 
    { field: 'fieldGoals1.diff', label: '1 point field goals diff' }, 
    { field: 'fieldGoals2.for', label: '2 point field goals for' }, 
    { field: 'fieldGoals2.against', label: '2 point field goals against' }, 
    { field: 'fieldGoals2.diff', label: '2 point field goals diff' }, 
    { field: 'sinBins.for', label: 'sin bins for' }, 
    { field: 'sinBins.against', label: 'sin bins against' }, 
    { field: 'sinBins.diff', label: 'sin bins diff' }, 
    { field: 'sendOffs.for', label: 'send offs for' }, 
    { field: 'sendOffs.against', label: 'send offs against' }, 
    { field: 'sendOffs.diff', label: 'send offs diff' }
];

const TeamListRowStyle = (record, index) => ({
    backgroundColor: Config.MyTeamCodes.includes(record.teamCode) ? '#efe' : '#fff'
});

const TeamStatHeader = ({ children }) => {
    const classes = useStyles();
    const { currentSort } = useListSortContext();
    return (
        <TableHead>
            <TableRow>
                <TableCell />
                <TableCell colSpan={3} className={classes.topHeader}>Points</TableCell>
                <TableCell colSpan={3} className={classes.topHeaderBorder}>Tries</TableCell>
                <TableCell colSpan={3} className={classes.topHeaderBorder}>Goals</TableCell>
                <TableCell colSpan={3} className={classes.topHeaderBorder}>1 Point Field Goals</TableCell>
                <TableCell colSpan={3} className={classes.topHeaderBorder}>2 Point Field Goals</TableCell>
                <TableCell colSpan={3} className={classes.topHeaderBorder}>Sin Bins</TableCell>
                <TableCell colSpan={3} className={classes.topHeaderBorder}>Send Offs</TableCell>
            </TableRow>
            <TableRow>
                {React.Children.map(children, child => (
                    <TableCell key={child.props.source} className={child.props.source && child.props.source !== 'points.for' && child.props.source.endsWith('.for') ? classes.cellBorder : (child.props.label === 'Team' ? classes.cellLeft : classes.cell)}>
                        {child.props.label}{teamStatsSortFields.find(f => f.field === child.props.source) && currentSort.field === teamStatsSortFields.find(f => f.field === child.props.source).label
                            ? (currentSort.order === 'DESC' ? <span> &darr;</span> : <span> &uarr;</span>)
                            :  null}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

export const TeamStatList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const classes = useStyles();

    const TeamStatFilter = props => (
        <Filter {...props}>
            <ReferenceInput label="Comp Season" source="competitionSeasonId" reference="competition-season" perPage={1000} alwaysOn allowEmpty={false}>
                <SelectInput optionText={record => `${record.name} ${record.seasonYear}`} />
            </ReferenceInput>
            <RadioButtonGroupInput label="Display" source="showAverages" choices={[
                { id: false, name: 'Totals' },
                { id: true, name: 'Averages' }
            ]} alwaysOn />
        </Filter>
    );

    const StatField = (props) => <FunctionField {...props} render={record => <NumberField {...props} options={{ minimumFractionDigits: record.showAverages ? 1 : 0 }} />} />;

    return (
        <List {...props} 
            bulkActionButtons={false} 
            filters={<TeamStatFilter />}
            filterDefaultValues={{ competitionSeasonId: Config.CurrentCompetitionSeasonId, showAverages: false }}
            actions={<ListActions sortFields={teamStatsSortFields.map(f => f.label)} />}
            sort={{ field: 'points for', order: 'DESC' }}
            pagination={null}
        >
            {isSmall ? (
                <Datagrid
                    classes={{ rowCell: classes.cellSmall, headerCell: classes.cellSmall }}
                    rowStyle={TeamListRowStyle}
                    rowClick={(id, basePath, record) => `/stat/teams/${record.competitionSeasonId};${record.id}/show`}
                >
                    <LogoField type="teams" source="teamCode" text="teamCode" label="Team" />
                    <StatField source="points.for" label="Pts" />                
                    <StatField source="tries.for" label="T" />
                    <StatField source="goals.for" label="G" />
                    <StatField source="fieldGoals1.for" label="FG1" />
                    <StatField source="fieldGoals2.for" label="FG2" />
                    <StatField source="sinBins.for" label="SB" />
                    <StatField source="sendOffs.for" label="SO" />
                </Datagrid>
            ) : (
                <Datagrid
                    header={<TeamStatHeader />}
                    classes={{ rowCell: classes.cell, headerCell: classes.cell }}
                    rowStyle={TeamListRowStyle}
                    rowClick={(id, basePath, record) => `/stat/teams/${record.competitionSeasonId};${record.id}/show`}
                >
                    <FunctionField render={record => record.competitionCode === 'NRL' 
                        ? <LogoField type="teams" source="teamCode" text="teamNickname" /> 
                        : <LogoField type="teams" source="teamCode" text="teamName" />
                    } label="Team" cellClassName={classes.cellLeft} />
                    <StatField source="points.for" label="F" />
                    <StatField source="points.against" label="A" />
                    <StatField source="points.diff" label="+/-" />
                    <StatField source="tries.for" label="F" cellClassName={classes.cellBorder} />
                    <StatField source="tries.against" label="A" />
                    <StatField source="tries.diff" label="+/-" />
                    <StatField source="goals.for" label="F" cellClassName={classes.cellBorder} />
                    <StatField source="goals.against" label="A" />
                    <StatField source="goals.diff" label="+/-" />
                    <StatField source="fieldGoals1.for" label="F" cellClassName={classes.cellBorder} />
                    <StatField source="fieldGoals1.against" label="A" />
                    <StatField source="fieldGoals1.diff" label="+/-" />
                    <StatField source="fieldGoals2.for" label="F" cellClassName={classes.cellBorder} />
                    <StatField source="fieldGoals2.against" label="A" />
                    <StatField source="fieldGoals2.diff" label="+/-" />
                    <StatField source="sinBins.for" label="F" cellClassName={classes.cellBorder} />
                    <StatField source="sinBins.against" label="A" />
                    <StatField source="sinBins.diff" label="+/-" />
                    <StatField source="sendOffs.for" label="F" cellClassName={classes.cellBorder} />
                    <StatField source="sendOffs.against" label="A" />
                    <StatField source="sendOffs.diff" label="+/-" />
                </Datagrid>
            )}
        </List>
    );
};

export const TeamStatShow = props => {
    const classes = useStyles();
    const { loading } = useShowController(props);
    if (loading) return null;

    const Title = ({ record }) => <span>Team Stats Breakdown - {record.data.competitionCode} {record.data.seasonYear} - {record.data.competitionCode === 'NRL' ? record.data.team.teamNickname : record.data.team.teamName}</span>;

    const StatRow = ({ source, label }) => (
        <TableRow>
            <TableCell className={classes.cellLeft}>{label}</TableCell>
            <TableCell className={classes.cell}><NumberField source={`${source}.for`} /></TableCell>
            <TableCell className={classes.cell}><NumberField source={`${source}.against`} /></TableCell>
            <TableCell className={classes.cell}><NumberField source={`${source}.diff`} /></TableCell>
        </TableRow>
    );

    return (
        <Show {...props} title={<Title />}>
            <SimpleShowLayout>
                <LogoField type="competitions" source="data.competitionCode" text="data.competitionCode" label="Competition" />
                <TextField source="data.seasonYear" label="Season" />
                <FunctionField render={record => record.data.competitionCode === 'NRL' 
                    ? <LogoField type="teams" source="data.team.teamCode" text="data.team.teamNickname" /> 
                    : <LogoField type="teams" source="data.team.teamCode" text="data.team.teamName" />
                } label="Team" cellClassName={classes.cellLeft} />
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.cellLeft}>Stat</TableCell>
                            <TableCell className={classes.cell}>F</TableCell>
                            <TableCell className={classes.cell}>A</TableCell>
                            <TableCell className={classes.cell}>+/-</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <StatRow source="data.team.points" label="Points" />
                        <StatRow source="data.team.tries" label="Tries" />
                        <StatRow source="data.team.goals" label="Goals" />
                        <StatRow source="data.team.fieldGoals1" label="1 Point Field Goals" />
                        <StatRow source="data.team.fieldGoals2" label="2 Point Field Goals" />
                        <StatRow source="data.team.sinBins" label="Sin Bins" />
                        <StatRow source="data.team.sendOffs" label="Send Offs" />
                    </TableBody>          
                </Table>
            </SimpleShowLayout>
        </Show>
    );
};

/*** PLAYER STATS ***/
const playerStatsSortFields = [
    { field: 'lastName', label: 'name' }, 
    { field: 'primaryPosition.name', label: 'primary position' },  
    { field: 'teamRecord.played', label: 'played' }, 
    { field: 'teamRecord.won', label: 'won' },
    { field: 'teamRecord.drawn', label: 'drawn' },
    { field: 'teamRecord.lost', label: 'lost' },
    { field: 'teamRecord.winPercentage', label: 'win percentage' },
    { field: 'tries', label: 'tries' },
    { field: 'goals', label: 'goals' },
    { field: 'fieldGoals1', label: '1 point field goals' },
    { field: 'fieldGoals2', label: '2 point field goals' },
    { field: 'points', label: 'points' },
    { field: 'sinBins', label: 'sin bins' },
    { field: 'sendOffs', label: 'send offs' }
];

const PlayerStatHeader = ({ children }) => {
    const classes = useStyles();
    const { currentSort } = useListSortContext();
    return (
        <TableHead>
            <TableRow>
                <TableCell colSpan={2} />
                <TableCell colSpan={8} className={classes.topHeader}>Results</TableCell>
                <TableCell colSpan={7} className={classes.topHeaderBorder}>Stats</TableCell>
            </TableRow>
            <TableRow>
                {React.Children.map(children, child => (
                    <TableCell key={child.props.source} className={child.props.source && child.props.source === 'tries' ? classes.cellBorder : (child.props.label === 'Player' || child.props.label === 'Primary Position' ? classes.cellLeft : classes.cell)}>
                        {child.props.label}{playerStatsSortFields.find(f => f.field === child.props.source) && currentSort.field === playerStatsSortFields.find(f => f.field === child.props.source).label
                            ? (currentSort.order === 'DESC' ? <span> &darr;</span> : <span> &uarr;</span>)
                            :  null}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

export const PlayerStatList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const classes = useStyles();
    return (
        <List {...props} 
            bulkActionButtons={false}
            filters={<CompetitionSeasonAndTeamFilter enableTeam={false} />}
            filterDefaultValues={{ competitionSeasonId: Config.CurrentCompetitionSeasonId, teamId: Config.MyTeamId }}
            actions={<ListActions sortFields={playerStatsSortFields.map(f => f.label)} />}
            sort={{ field: 'name', order: 'ASC' }}
            pagination={null}
        >
            {isSmall ? (
                <Datagrid
                    classes={{ rowCell: classes.cellSmall, headerCell: classes.cellSmall }}
                    rowClick={(id, basePath, record) => `/stat/players/${record.competitionSeasonId};${record.id}/show`}
                >
                    <FunctionField render={record => 
                        <span style={{whiteSpace:'nowrap'}}><LogoField type="players" source="id" extension="png" fallback="player.png" width={30} additionalText={`${record.firstName.substring(0,1)}. ${(record.lastName.length > 8 ? `${record.lastName.substring(0,8)}…` : record.lastName)} (${record.primaryPosition.code})`} /></span>
                    } label="Player" />
                    <TextField source="teamRecord.played" label="P" />
                    <TextField source="teamRecord.winPercentage" label="W%" />   
                    <TextField source="tries" label="T" emptyText="-" />
                    <TextField source="goals" label="G" emptyText="-" />
                    <FunctionField render={record => record.fieldGoals1 !== null ? record.fieldGoals1 + record.fieldGoals2 : '-'} label="FG" />
                    <TextField source="points" label="Pts" emptyText="-" />
                </Datagrid>
            ) : (
                <Datagrid 
                    header={<PlayerStatHeader />}
                    classes={{ rowCell: classes.cell, headerCell: classes.cell }}
                    rowClick={(id, basePath, record) => `/stat/players/${record.competitionSeasonId};${record.id}/show`}
                >
                    <FunctionField source="lastName" render={record =>
                        <LogoField type="players" source="id" text="firstName" extension="png" fallback="player.png" height={48} width={58} additionalText={record.lastName} />
                    } label="Player" cellClassName={classes.cellLeft} />
                    <TextField source="primaryPosition.name" label="Primary Position" cellClassName={classes.cellLeft} />
                    <TextField source="teamRecord.played" label="P"/>
                    <TextField source="teamRecord.won" label="W" />
                    <TextField source="teamRecord.drawn" label="D" />
                    <TextField source="teamRecord.lost" label="L" />
                    <TextField source="teamRecord.winPercentage" label="W%" />
                    <TextField source="teamRecord.pointsFor" label="F" />
                    <TextField source="teamRecord.pointsAgainst" label="A" />
                    <TextField source="teamRecord.pointsDiff" label="+/-" />       
                    <TextField source="tries" label="T" emptyText="-" cellClassName={classes.cellBorder}  />
                    <TextField source="goals" label="G" emptyText="-" />
                    <TextField source="fieldGoals1" label="FG1" emptyText="-" />
                    <TextField source="fieldGoals2" label="FG2" emptyText="-" />
                    <TextField source="points" label="Pts" emptyText="-"/>
                    <TextField source="sinBins" label="SB" emptyText="-" />
                    <TextField source="sendOffs" label="SO" emptyText="-" />
                </Datagrid>
            )}
        </List>
    );
};

const PlayerMatchHeader = ({ children }) => {
    const classes = useStyles();
    return (
        <TableHead>
            <TableRow>
                <TableCell colSpan={5} className={classes.topHeader}>Match</TableCell>
                <TableCell colSpan={2} className={classes.topHeaderBorder}>Position</TableCell>
                <TableCell colSpan={7} className={classes.topHeaderBorder}>Stats</TableCell>
            </TableRow>
            <TableRow>
                {React.Children.map(children, child => (
                    <TableCell key={child.props.source} className={child.props.source && child.props.source === 'tries' ? classes.cellBorder : (child.props.source === 'position.name' ? classes.cellBorderLeft : (['Round','Opponent','Venue','Date','Time','Position','Location'].includes(child.props.label) ? classes.cellLeft : classes.cell))}>
                        {child.props.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

export const PlayerStatShow = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const classes = useStyles();
    const { loading, record } = useShowController(props);
    if (loading) return null;

    const Title = ({ record }) => <span>Player Stats Breakdown - {record.data.competitionCode} {record.data.seasonYear} - {record.data.firstName} {record.data.lastName}</span>;

    return (
        <Show {...props} title={<Title />}>
            <SimpleShowLayout>
                <LogoField type="competitions" source="data.competitionCode" text="data.competitionCode" label="Competition" />
                <TextField source="data.seasonYear" label="Season" />
                <FunctionField render={rec =>
                    <LogoField type="players" source="data.playerId" text="data.firstName" extension="png" fallback="player.png" height={48} width={58} additionalText={rec.data.lastName} />
                } label="Player" cellClassName={classes.cellLeft} />
                <ArrayField source="data.matches" addLabel={false}>
                    {isSmall ? (
                        <Datagrid classes={{ rowCell: classes.cellSmall, headerCell: classes.cellSmall }}>
                            <TextField source="roundNumber" label="Rd" />
                            <LogoField type="teams" source="opponentTeamCode" text="opponentTeamCode" label="Opponent" cellClassName={classes.cellLeft} />
                            <TextField source="position.code" label="Pos" />               
                            <TextField source="tries" label="T" emptyText="-" />
                            <TextField source="goals" label="G" emptyText="-" />
                            <TextField source="fieldGoals1" label="FG1" emptyText="-" />
                            <TextField source="fieldGoals2" label="FG2" emptyText="-" />
                            <TextField source="points" label="Pts" emptyText="-" />
                        </Datagrid>
                    ) : (
                        <Datagrid
                            header={<PlayerMatchHeader />}
                            classes={{ rowCell: classes.cell, headerCell: classes.cell }}
                        > 
                            <FunctionField render={data => `${data.roundName}${data.pool ? ` (Pool ${data.pool})` : ''}`} label="Round" cellClassName={classes.cellLeft} />
                            <FunctionField render={data => record.competitionCode === 'NRL' 
                                ? <LogoField type="teams" source="opponentTeamCode" text="opponentTeamNickname" /> 
                                : <LogoField type="teams" source="opponentTeamCode" text="opponentTeamName" />
                            } label="Opponent" cellClassName={classes.cellLeft} />
                            <TextField source="venueName" label="Venue" cellClassName={classes.cellLeft} />
                            <DateField source="startDateTime" label="Date" options={{ weekday: 'short', day: 'numeric', month: 'long' }} locales="en-AU" cellClassName={classes.cellLeft} />
                            <TimeWithZoneField source="startDateTime" includeDate={false} label="Time" cellClassName={classes.cellLeft} />
                            <TextField source="position.name" label="Position" cellClassName={classes.cellBorderLeft} />
                            <TextField source="position.location" label="Location" cellClassName={classes.cellLeft} />                
                            <TextField source="tries" label="T" emptyText="-" cellClassName={classes.cellBorder} />
                            <TextField source="goals" label="G" emptyText="-" />
                            <TextField source="fieldGoals1" label="FG1" emptyText="-" />
                            <TextField source="fieldGoals2" label="FG2" emptyText="-" />
                            <TextField source="points" label="Pts" emptyText="-" />
                            <TextField source="sinBins" label="SB" emptyText="-" />
                            <TextField source="sendOffs" label="SO" emptyText="-" />
                        </Datagrid>
                    )}
                </ArrayField>
            </SimpleShowLayout>
        </Show>
    );
};

/*** POINTS BY QUARTER ***/
const pointsByQuarterSortFields = [
    { field: 'roundName', label: 'round' },
    { field: 'firstQuarter.for', label: '1st quarter for' }, 
    { field: 'firstQuarter.against', label: '1st quarter against' }, 
    { field: 'firstQuarter.diff', label: '1st quarter diff' }, 
    { field: 'secondQuarter.for', label: '2nd quarter for' }, 
    { field: 'secondQuarter.against', label: '2nd quarter against' }, 
    { field: 'secondQuarter.diff', label: '2nd quarter diff' }, 
    { field: 'thirdQuarter.for', label: '3rd quarter for' }, 
    { field: 'thirdQuarter.against', label: '3rd quarter against' }, 
    { field: 'thirdQuarter.diff', label: '3rd quarter diff' }, 
    { field: 'fourthQuarter.for', label: '4th quarter for' }, 
    { field: 'fourthQuarter.against', label: '4th quarter against' }, 
    { field: 'fourthQuarter.diff', label: '4th quarter diff' }, 
    { field: 'firstHalf.for', label: '1st half for' }, 
    { field: 'firstHalf.against', label: '1st half against' }, 
    { field: 'firstHalf.diff', label: '1st half diff' }, 
    { field: 'secondHalf.for', label: '2nd half for' }, 
    { field: 'secondHalf.against', label: '2nd half against' }, 
    { field: 'secondHalf.diff', label: '2nd half diff' }, 
    { field: 'extraTime.for', label: 'extra time for' }, 
    { field: 'extraTime.against', label: 'extra time against' }, 
    { field: 'extraTime.diff', label: 'extra time diff' }
];

const PointsByQuarterListRowStyle = (record, index) => ({
    backgroundColor: record.extraTime !== null ? '#f9f5de' : '#fff'
});

const PointsByQuarterHeader = ({ children }) => {
    const classes = useStyles();
    const { currentSort } = useListSortContext();
    return (
        <TableHead>
            <TableRow>
                <TableCell colSpan={2} />
                <TableCell colSpan={3} className={classes.topHeaderBorder}>1st - 20th Minute</TableCell>
                <TableCell colSpan={3} className={classes.topHeaderBorder}>21st - 40th Minute</TableCell>
                <TableCell colSpan={3} className={classes.topHeaderBorder}>41st - 60th Minute</TableCell>
                <TableCell colSpan={3} className={classes.topHeaderBorder}>61st - 80th Minute</TableCell>
                <TableCell colSpan={3} className={classes.topHeaderBorderDouble}>First Half</TableCell>
                <TableCell colSpan={3} className={classes.topHeaderBorder}>Second Half</TableCell>
                <TableCell colSpan={3} className={classes.topHeaderBorder}>Extra Time</TableCell>
            </TableRow>
            <TableRow>
                {React.Children.map(children, child => (
                    <TableCell key={child.props.source} className={child.props.source && child.props.source === 'firstHalf.for' ? classes.cellBorderDouble : (child.props.source && child.props.source.endsWith('.for') ? classes.cellBorder : (child.props.label === 'Round' || child.props.label === 'Opponent' ? classes.cellLeft : classes.cell))}>
                        {child.props.label}{pointsByQuarterSortFields.find(f => f.field === child.props.source) && currentSort.field === pointsByQuarterSortFields.find(f => f.field === child.props.source).label
                            ? (currentSort.order === 'DESC' ? <span> &darr;</span> : <span> &uarr;</span>)
                            :  null}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

const PointsByQuarterForField = props => {
    const { source, threshold } = props;
    const classes = useStyles();
    const record = useRecordContext(props);
    const data = get(record, source);

    return <Typography component="span" variant="body2" className={data >= threshold ? classes.green : null}>{data}</Typography>;
}

const PointsByQuarterAgainstField = props => {
    const { source, threshold } = props;
    const classes = useStyles();
    const record = useRecordContext(props);
    const data = get(record, source);

    return <Typography component="span" variant="body2" className={data >= threshold ? classes.red : null}>{data}</Typography>;
}

export const PointsByQuarterList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const classes = useStyles();
    return (
        <List {...props} 
            bulkActionButtons={false}
            filters={<CompetitionSeasonAndTeamFilter enableTeam={true} />}
            filterDefaultValues={{ competitionSeasonId: Config.CurrentCompetitionSeasonId, teamId: Config.MyTeamId }}
            actions={<ListActions sortFields={pointsByQuarterSortFields.map(f => f.label)} />}
            sort={{ field: 'round', order: 'DESC' }}
            pagination={null}
        >
            {isSmall ? (
                <Datagrid classes={{ rowCell: classes.cellSmall, headerCell: classes.cellSmall }} rowStyle={PointsByQuarterListRowStyle}>
                    <TextField source="roundNumber" label="Rd" />
                    <LogoField type="teams" source="opponentCode" text="opponentCode" label="Opp" />
                    <FunctionField render={record => (
                        <div>
                            <span className={record.firstQuarter.for >= 10 ? classes.greenSmall : null}>{record.firstQuarter.for}</span>
                            <span>-</span>
                            <span className={record.firstQuarter.against >= 10 ? classes.redSmall : null}>{record.firstQuarter.against}</span>
                        </div>
                    )} label="Q1" />
                    <FunctionField render={record => (
                        <div>
                            <span className={record.secondQuarter.for >= 10 ? classes.greenSmall : null}>{record.secondQuarter.for}</span>
                            <span>-</span>
                            <span className={record.secondQuarter.against >= 10 ? classes.redSmall : null}>{record.secondQuarter.against}</span>
                        </div>
                    )} label="Q2" />
                    <FunctionField render={record => (
                        <div>
                            <span className={record.thirdQuarter.for >= 10 ? classes.greenSmall : null}>{record.thirdQuarter.for}</span>
                            <span>-</span>
                            <span className={record.thirdQuarter.against >= 10 ? classes.redSmall : null}>{record.thirdQuarter.against}</span>
                        </div>
                    )} label="Q3" />
                    <FunctionField render={record => (
                        <div>
                            <span className={record.fourthQuarter.for >= 10 ? classes.greenSmall : null}>{record.fourthQuarter.for}</span>
                            <span>-</span>
                            <span className={record.fourthQuarter.against >= 10 ? classes.redSmall : null}>{record.fourthQuarter.against}</span>
                        </div>
                    )} label="Q4" />
                    <FunctionField render={record => (
                        <div>
                            <span className={record.firstHalf.for >= 20 ? classes.greenSmall : null}>{record.firstHalf.for}</span>
                            <span>-</span>
                            <span className={record.firstHalf.against >= 20 ? classes.redSmall : null}>{record.firstHalf.against}</span>
                        </div>
                    )} label="H1" />
                    <FunctionField render={record => (
                        <div>
                            <span className={record.secondHalf.for >= 20 ? classes.greenSmall : null}>{record.secondHalf.for}</span>
                            <span>-</span>
                            <span className={record.secondHalf.against >= 20 ? classes.redSmall : null}>{record.secondHalf.against}</span>
                            {record.extraTime === null ? null : <div className={record.extraTime.for >= 1 ? classes.greenSmall : (record.extraTime.against >= 1 ? classes.redSmall : null)}>({record.extraTime.for}-{record.extraTime.against})</div>}
                        </div>
                    )} label="H2" />
                </Datagrid>
            ) : (
                <Datagrid header={<PointsByQuarterHeader />} classes={{ rowCell: classes.cell, headerCell: classes.cell }} rowStyle={PointsByQuarterListRowStyle}>
                    <TextField source="roundName" label="Round" cellClassName={classes.cellLeft} />
                    <FunctionField render={record => record.competitionCode === 'NRL' 
                        ? <LogoField type="teams" source="opponentCode" text="opponentNickname" /> 
                        : <LogoField type="teams" source="opponentCode" text="opponentName" />
                    } label="Opponent" cellClassName={classes.cellLeft} />
                    <PointsByQuarterForField source="firstQuarter.for" threshold={10} label="F" cellClassName={classes.cellBorder} />
                    <PointsByQuarterAgainstField source="firstQuarter.against" threshold={10} label="A" />
                    <TextField source="firstQuarter.diff" label="+/-" />
                    <PointsByQuarterForField source="secondQuarter.for" threshold={10} label="F" cellClassName={classes.cellBorder} />
                    <PointsByQuarterAgainstField source="secondQuarter.against" threshold={10} label="A" />
                    <TextField source="secondQuarter.diff" label="+/-" />
                    <PointsByQuarterForField source="thirdQuarter.for" threshold={10} label="F" cellClassName={classes.cellBorder} />
                    <PointsByQuarterAgainstField source="thirdQuarter.against" threshold={10} label="A" />
                    <TextField source="thirdQuarter.diff" label="+/-" />
                    <PointsByQuarterForField source="fourthQuarter.for" threshold={10} label="F" cellClassName={classes.cellBorder} />
                    <PointsByQuarterAgainstField source="fourthQuarter.against" threshold={10} label="A" />
                    <TextField source="fourthQuarter.diff" label="+/-" />
                    <PointsByQuarterForField source="firstHalf.for" threshold={20} label="F" cellClassName={classes.cellBorderDouble} />
                    <PointsByQuarterAgainstField source="firstHalf.against" threshold={20} label="A" />
                    <TextField source="firstHalf.diff" label="+/-" />
                    <PointsByQuarterForField source="secondHalf.for" threshold={20} label="F" cellClassName={classes.cellBorder} />
                    <PointsByQuarterAgainstField source="secondHalf.against" threshold={20} label="A" />
                    <TextField source="secondHalf.diff" label="+/-" />
                    <PointsByQuarterForField source="extraTime.for" threshold={1} label="F" emptyText="-" cellClassName={classes.cellBorder} />
                    <PointsByQuarterAgainstField source="extraTime.against" threshold={1} label="A" emptyText="-" />
                    <TextField source="extraTime.diff" label="+/-" emptyText="-" />
                </Datagrid>
            )}
        </List>
    );
};

/*** DRAW DIFFICULTY ***/
const getDrawDifficultySortFields = data => {
    return [ 
        { field: 'games', label: 'games' },
        { field: 'top4', label: 'top 4' }, 
        { field: 'inFinals', label: `top ${data.teamsInFinals}` }, 
        { field: 'notInFinals', label: `bottom ${data.teamsNotInFinals}` }, 
        { field: 'bottom4', label: 'bottom 4' }, 
        { field: 'oppositionLadderPosition.total', label: 'opposition ladder position total' }, 
        { field: 'oppositionLadderPosition.average', label: 'opposition ladder position average' }
    ];
};

const GetDrawDifficultyTeamsInFinals = () => {
    const { data, ids, total, loaded } = useListContext();

    let teamsInFinals = 0;
    let totalTeams = 0;

    if (loaded) {
        teamsInFinals = data[ids[0]].teamsInFinals;
        totalTeams = total;
    }

    return { teamsInFinals: teamsInFinals, teamsNotInFinals: totalTeams - teamsInFinals };
}

const DrawDifficultyHeader = ({ children }) => {
    const classes = useStyles();
    const { currentSort } = useListSortContext();
    const data = GetDrawDifficultyTeamsInFinals();
    const drawDifficultySortFields = getDrawDifficultySortFields(data);
    return (
        <TableHead>
            <TableRow>
                <TableCell colSpan={2} />
                <TableCell colSpan={2} className={classes.topHeader}>Games vs Top Opposition</TableCell>
                <TableCell colSpan={2} className={classes.topHeaderBorder}>Games vs Bottom Opposition</TableCell>
                <TableCell colSpan={2} className={classes.topHeaderBorder}>Opposition Ladder Position</TableCell>
            </TableRow>
            <TableRow>
                {React.Children.map(children, child => (
                    <TableCell key={child.props.source} className={child.props.source && ['top4','notInFinals','oppositionLadderPosition.total'].includes(child.props.source) ? classes.cellBorder : (child.props.label === 'Team' ? classes.cellLeft : classes.cell)}>
                        {child.props.label}{drawDifficultySortFields.find(f => f.field === child.props.source) && currentSort.field === drawDifficultySortFields.find(f => f.field === child.props.source).label
                            ? (currentSort.order === 'DESC' ? <span> &darr;</span> : <span> &uarr;</span>)
                            :  null}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

export const DrawDifficultyList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const classes = useStyles();

    const DrawDifficultyListActions = () => {
        const data = GetDrawDifficultyTeamsInFinals();
        const drawDifficultySortFields = getDrawDifficultySortFields(data);
        return <ListActions sortFields={drawDifficultySortFields.map(f => f.label)} />;
    };

    const DrawDifficultyListContents = () => {

        const data = GetDrawDifficultyTeamsInFinals();
        const inFinalsLabel = `Top ${data.teamsInFinals}`;
        const notInFinalsLabel = `Bottom ${data.teamsNotInFinals}`;

        return isSmall ? (
            <Datagrid classes={{ rowCell: classes.cellSmall, headerCell: classes.cellSmall }} rowStyle={TeamListRowStyle}>
                <LogoField type="teams" source="teamCode" text="teamCode" label="Team" />
                <TextField source="top4" label="Top 4" />
                <TextField source="inFinals" label={inFinalsLabel} />
                <TextField source="notInFinals" label={notInFinalsLabel} />
                <TextField source="bottom4" label="Bottom 4" />
                <TextField source="oppositionLadderPosition.average" label="Avg" />
            </Datagrid>
        ) : (
            <Datagrid header={<DrawDifficultyHeader />} classes={{ rowCell: classes.cell, headerCell: classes.cell }} rowStyle={TeamListRowStyle}>
                <FunctionField render={record => record.competitionCode === 'NRL' 
                    ? <LogoField type="teams" source="teamCode" text="teamNickname" /> 
                    : <LogoField type="teams" source="teamCode" text="teamName" />
                } label="Team" cellClassName={classes.cellLeft} />
                <TextField source="games" label="Games" />
                <TextField source="top4" label="Top 4" cellClassName={classes.cellBorder} />
                <TextField source="inFinals" label={inFinalsLabel} />
                <TextField source="notInFinals" label={notInFinalsLabel} cellClassName={classes.cellBorder} />
                <TextField source="bottom4" label="Bottom 4" />
                <TextField source="oppositionLadderPosition.total" label="Total" cellClassName={classes.cellBorder} />
                <NumberField source="oppositionLadderPosition.average" label="Average" options={{ minimumFractionDigits: 2 }} />
            </Datagrid>
        )
    };

    return (
        <List {...props} 
            bulkActionButtons={false} 
            filters={<CompetitionSeasonFilter />}
            filterDefaultValues={{ competitionSeasonId: Config.CurrentCompetitionSeasonId }}
            actions={<DrawDifficultyListActions />}
            sort={{ field: 'opposition ladder position average', order: 'ASC' }}
            pagination={null}
        >
            <DrawDifficultyListContents />
        </List>
    );
};
