import { Layout, AppBar, MenuItemLink, getResources } from 'react-admin';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    appBar: {
        backgroundColor: '#212529'
    },
    icon: {
        height: '30px',
        paddingRight: '12px'
    },
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        color: '#fed136'
    },
    menu: {
        padding: '1em 0 1em 0'
    },
    menuCategory: {
        fontSize: '.8em',
        color: '#212529',
        padding: '.5em 1em .5em 1.25em'
    }
});

const CustomAppBar = props => {
    const classes = useStyles();
    return (
        <AppBar {...props} className={classes.appBar}>
            <img src="icon-white-sm.png" alt="Stats" className={classes.icon} />
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
        </AppBar>
    );
};

const CustomMenu = () => {
    const classes = useStyles();
    const resources = useSelector(getResources);
    const categories = [...new Set(resources.map(resource => resource.options.category).filter(category => category !== undefined))];
    const sidebarIsOpen = useSelector(state => state.admin.ui.sidebarOpen);

    return (
        <div className={classes.menu}>
            {categories.map(category => (
                <div key={category}>
                    {sidebarIsOpen && <div className={classes.menuCategory}>{category}</div>}
                    {resources.map(resource => resource.options.category === category && (
                        <MenuItemLink key={resource.name} to={`/${resource.name}`} primaryText={resource.options.label} leftIcon={resource.icon ? <resource.icon /> : null} />
                    ))}
                </div>
            ))}
        </div>
    );
}

const CustomLayout = props => <Layout {...props} appBar={CustomAppBar} menu={CustomMenu} />;

export default CustomLayout;