import * as React from "react";
import { 
    List, Show, Edit, Create, 
    Datagrid, SimpleShowLayout, SimpleForm, 
    TextField, TextInput, 
    required, useNotify, useRedirect
} from 'react-admin';
import LogoField from '../components/LogoField';
import DateTimeField from '../components/DateTimeField';

export const CompetitionList = props => (
    <List {...props} bulkActionButtons={false}>
        <Datagrid rowClick="show">
            <LogoField type="competitions" source="code" text="name" label="Name" />
            <TextField source="code" label="Code" /> 
        </Datagrid>
    </List>
);

export const CompetitionShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" label="ID" />
            <TextField source="name" label="Name" />
            <TextField source="code" label="Code" /> 
            <DateTimeField source="createdUtc" label="Created" />
            <DateTimeField source="lastUpdatedUtc" label="Last Updated" />
        </SimpleShowLayout>
    </Show>
);

export const CompetitionEdit = props => (
    <Edit {...props} mutationMode="optimistic">
        <SimpleForm>
            <TextInput source="id" label="ID" fullWidth={true} disabled />
            <TextInput source="name" label="Name" fullWidth={true} validate={[required()]} />
            <TextInput source="code" label="Code" fullWidth={true} validate={[required()]} format={x => x == null ? null : x.toUpperCase()} />
        </SimpleForm>
    </Edit>
);

export const CompetitionCreate = props => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = ({ data }) => {
		notify(`Competition '${data.name}' successfully created.`);
		redirect(`/competition/${data.id}/show`);
	};

    return (
        <Create onSuccess={onSuccess} {...props}>
            <SimpleForm>
                <TextInput source="name" label="Name" fullWidth={true} validate={[required()]} />
                <TextInput source="code" label="Code" fullWidth={true} validate={[required()]} format={x => x == null ? null : x.toUpperCase()} />
            </SimpleForm>
        </Create>
    );
};