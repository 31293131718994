import { fetchUtils } from 'react-admin';
import Config from "./configProvider";

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('auth');
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
};

const getCompetitionCode = async (competitionId) => {
    const { data } = await httpClient(`${Config.API}/competition/${competitionId}`)
        .then(({ json }) => ({ 
            data: json 
        }));
    return data.code;
}

const getSeasonYear = async (seasonId) => {
    const { data } = await httpClient(`${Config.API}/season/${seasonId}`)
        .then(({ json }) => ({ 
            data: json 
        }));
    return data.year;
}

const getTeamCode = async (teamId) => {
    const { data } = await httpClient(`${Config.API}/team/${teamId}`)
        .then(({ json }) => ({ 
            data: json 
        }));
    return data.code;
}

const dataProvider = {
    getList: async (resource, params) => {
        let url = `${Config.API}/${resource}?pageNumber=${params.pagination.page}&pageSize=${params.pagination.perPage}`;
        if (resource === 'match' || resource === 'team-results') {
            url = `${Config.API}/match/search?pageNumber=${params.pagination.page}&pageSize=${params.pagination.perPage}`;
            let filteredTeamCode = null;
            if (params.filter.seasonId !== undefined)
                url += `&season=${await getSeasonYear(params.filter.seasonId)}`;
            if (params.filter.teamId !== undefined) {
                filteredTeamCode = await getTeamCode(params.filter.teamId);
                url += `&team=${filteredTeamCode}`;
            }
            if (params.filter.opponentId !== undefined) {
                url += `&opponent=${await getTeamCode(params.filter.opponentId)}`;
            }
            if (params.filter.playerId !== undefined) {
                url += `&player=${params.filter.playerId}`;
            }
            if (params.filter.coachId !== undefined) {
                url += `&coach=${params.filter.coachId}`;
            }
            if (params.filter.refereeId !== undefined) {
                url += `&referee=${params.filter.refereeId}`;
            }
            
            // Get the matches
            const result = await httpClient(url)
                .then(({ headers, json }) => ({
                    data: json === undefined || json === null ? [] : (resource === 'team-results' ? json.filter(x => x.status === 'Full Time') : json).map(resource => ({ 
                        ...resource, 
                        filteredByTeam: params.filter.teamId !== undefined,
                        filteredTeamCode: filteredTeamCode, 
                        filteredByPlayer: params.filter.playerId !== undefined, 
                        filteredByCoach: params.filter.coachId !== undefined, 
                        filteredByReferee: params.filter.refereeId !== undefined
                    })),
                    total: json === undefined || json === null ? 0 : parseInt(headers.get('content-range').split('/').pop(), 10),
                }));
            
            // Now get the byes for the NRL rounds in the result
            const filteredByTeam = result.data[0].filteredByTeam || result.data[0].filteredByPlayer || result.data[0].filteredByCoach || result.data[0].filteredByReferee;
            const competitionCode = 'NRL';
            const seasonYear = result.data[0].seasonYear;
            const startRound = filteredByTeam ? 1 : Math.min(...result.data.filter(x => x.competitionCode === competitionCode && x.seasonYear === seasonYear).map(x => x.roundNumber));
            let endRound = Math.max(...result.data.filter(x => x.competitionCode === competitionCode && x.seasonYear === seasonYear).map(x => x.roundNumber));
            if (filteredByTeam) endRound += 1;

            if (startRound < Infinity && endRound > -Infinity) {
                const byeResult = await httpClient(`${Config.API}/competition-season/${competitionCode}/${seasonYear}/byes?startRound=${startRound}&endRound=${endRound}`)
                    .then(({ headers, json }) => ({
                        data: json === undefined || json === null ? [] : (filteredByTeam ? json.byes.filter(x => x.teamCode === filteredTeamCode) : json.byes).map(resource => ({ 
                            id: `${resource.roundNumber}${resource.teamCode}`,
                            competitionCode: json.competitionCode,
                            seasonYear: json.seasonYear,
                            roundName: resource.roundName,
                            roundNumber: resource.roundNumber,
                            status: 'BYE',
                            homeTeamName: resource.teamName,
                            homeTeamNickname: resource.teamNickname,
                            homeTeamCode: resource.teamCode,
                            homeTeamLadderPosition: resource.teamLadderPosition,
                            awayTeamName: 'BYE',
                            awayTeamNickname: 'BYE',
                            awayTeamCode: 'BYE',
                            refereeName: '',
                            venueName: '',
                            startDateTime: '',
                            filteredByTeam: params.filter.teamId !== undefined,
                            filteredTeamCode: filteredTeamCode, 
                            filteredByPlayer: params.filter.playerId !== undefined,
                            filteredByCoach: params.filter.coachId !== undefined, 
                            filteredByReferee: params.filter.refereeId !== undefined,
                            firstMatchInRound: true,
                            isBye: true
                        }) )
                    }));
                
                if (byeResult.data.length > 0) {
                    byeResult.data.forEach(bye => {
                        const match = filteredByTeam ? result.data.find(x => x.roundNumber === bye.roundNumber + 1) : result.data.find(x => x.roundNumber === bye.roundNumber && x.firstMatchInRound === true);
                        let index = filteredByTeam ? result.data.findIndex(x => x.roundNumber === bye.roundNumber + 1) : result.data.findIndex(x => x.roundNumber === bye.roundNumber && x.firstMatchInRound === true);
                        
                        result.data.splice(index + 1, 0, bye);
                        
                        if (match) match.firstMatchInRound = false;
                    });
                }
            }

            result.data[result.data.length - 1].firstMatchInRound = false;

            return Promise.resolve(result);
        }
        else if (resource === 'player') {
            url = `${Config.API}/player/active?pageNumber=${params.pagination.page}&pageSize=${params.pagination.perPage}`;
            if (params.filter.isActive === true || params.filter.isActive === false) {
                url += `&isActive=${params.filter.isActive}`;
            }
            if (params.filter.searchText !== undefined && params.filter.searchText !== null) {
                url += `&searchText=${params.filter.searchText}`;
            }
        }
        else if ( resource === 'coach' || resource === 'referee') {
            if (params.filter.isActive === true || params.filter.isActive === false)
                url = `${Config.API}/${resource}/active?pageNumber=${params.pagination.page}&pageSize=${params.pagination.perPage}&isActive=${params.filter.isActive}`;
            else
                url = `${Config.API}/${resource}?pageNumber=${params.pagination.page}&pageSize=${params.pagination.perPage}`;
        }
        else if (resource === 'player-active') {
            url = `${Config.API}/player/active?pageNumber=${params.pagination.page}&pageSize=${params.pagination.perPage}&isActive=true`;
        }
        else if (resource === 'coach-active') {
            url = `${Config.API}/coach/active?pageNumber=${params.pagination.page}&pageSize=${params.pagination.perPage}&isActive=true`;
        }
        else if (resource === 'referee-active') {
            url = `${Config.API}/referee/active?pageNumber=${params.pagination.page}&pageSize=${params.pagination.perPage}&isActive=true`;
        }
        else if (resource === 'ladder') {
            url = `${Config.API}/competition-season/${await getCompetitionCode(params.filter.competitionId)}/${await getSeasonYear(params.filter.seasonId)}/ladder`;
            if (params.filter.startRound !== undefined) {
                url += `?startRound=${params.filter.startRound}`;
            }
            if (params.filter.endRound !== undefined) {
                url += `${params.filter.startRound === undefined ? '?' : '&'}endRound=${params.filter.endRound}`;
            }
            if (params.filter.showLive !== undefined) {
                url += `${params.filter.startRound === undefined && params.filter.endRound === undefined ? '?' : '&'}showLive=${params.filter.showLive}`;
            }

            const hasPools = Config.PoolCompetitionIds.includes(params.filter.competitionId);

            return httpClient(url)
                .then(({ json }) => ({ 
                    data: json.teams.map(resource => ({ 
                        ...resource, 
                        id: hasPools ? `${resource.pool}${resource.position}` : resource.position,
                        competitionCode: json.competitionCode, 
                        teamsInFinals: json.teamsInFinals,
                        hasPools: hasPools
                    }) ),
                    total: json.teams.length
                }));
        }
        else if (resource === 'stat/teams') {
            return httpClient(`${Config.API}/${resource}?competitionSeasonId=${params.filter.competitionSeasonId}&showAverages=${params.filter.showAverages}&sortBy=${params.sort.field}&sortOrder=${params.sort.order}`)
                .then(({ json }) => ({ 
                    data: json.data.teams.map(resource => ({ ...resource, competitionSeasonId: params.filter.competitionSeasonId, competitionCode: json.data.competitionCode, seasonYear: json.data.seasonYear, showAverages: params.filter.showAverages }) ),
                    total: json.data.teams.length
                }));
        }
        else if (resource === 'stat/players') {
            return httpClient(`${Config.API}/${resource}?competitionSeasonId=${params.filter.competitionSeasonId}&teamId=${params.filter.teamId}&sortBy=${params.sort.field}&sortOrder=${params.sort.order}`)
                .then(({ json }) => ({ 
                    data: json.data.players.map(resource => ({ ...resource, competitionSeasonId: params.filter.competitionSeasonId, competitionCode: json.data.competitionCode, seasonYear: json.data.seasonYear }) ),
                    total: json.data.players.length
                }));
        }
        else if (resource === 'stat/points-by-quarter') {
            return httpClient(`${Config.API}/${resource}?competitionSeasonId=${params.filter.competitionSeasonId}&teamId=${params.filter.teamId}&sortBy=${params.sort.field}&sortOrder=${params.sort.order}`)
                .then(({ json }) => ({ 
                    data: json.data.matches.map(resource => ({ ...resource, competitionCode: json.data.competitionCode, seasonYear: json.data.seasonYear }) ),
                    total: json.data.matches.length
                }));
        }
        else if (resource === 'stat/draw-difficulty') {
            return httpClient(`${Config.API}/${resource}?competitionSeasonId=${params.filter.competitionSeasonId}&sortBy=${params.sort.field}&sortOrder=${params.sort.order}`)
                .then(({ json }) => ({ 
                    data: json.data.teams.map(resource => ({ ...resource, competitionCode: json.data.competitionCode, seasonYear: json.data.seasonYear, teamsInFinals: json.data.teamsInFinals }) ),
                    total: json.data.teams.length
                }));
        }
        else if (resource === 'competition-season' && params.filter.competitionCode) {
            return httpClient(`${Config.API}/${resource}?pageNumber=${params.pagination.page}&pageSize=${params.pagination.perPage}`)
                .then(({ headers, json }) => ({ 
                    data: json.filter(x => x.competitionCode === params.filter.competitionCode),
                    total: parseInt(headers.get('content-range').split('/').pop(), 10)
                }));
        }
        else if (resource === 'competition' && params.filter.competitionIds) {
            return httpClient(`${Config.API}/${resource}?pageNumber=${params.pagination.page}&pageSize=${params.pagination.perPage}`)
                .then(({ headers, json }) => ({ 
                    data: json.filter(x => params.filter.competitionIds.includes(x.id)),
                    total: parseInt(headers.get('content-range').split('/').pop(), 10)
                }));
        }

        return httpClient(url)
            .then(({ headers, json }) => ({
                data: json,
                total: parseInt(headers.get('content-range').split('/').pop(), 10),
            }));
    },

    getMany: (resource, params) => {
        let url = `${Config.API}/${resource}?pageNumber=1&pageSize=1000`;
        if (resource === 'player-active')
            url = `${Config.API}/player/active?pageNumber=1&pageSize=1000&isActive=true`;
        else if (resource === 'coach-active')
            url = `${Config.API}/coach/active?pageNumber=1&pageSize=1000&isActive=true`;
        else if (resource === 'referee-active')
            url = `${Config.API}/referee/active?pageNumber=1&pageSize=1000&isActive=true`;

        return httpClient(url)
            .then(({ headers, json }) => ({
                data: json,
                total: parseInt(headers.get('content-range').split('/').pop(), 10),
            }));
    },

    getOne: async (resource, params) => {
        let url = `${Config.API}/${resource}/${params.id}`;
        if (resource === 'match-event-list')
            url = `${Config.API}/match/${params.id}/scores`;
        else if (resource === 'match-weather')
            url = `${Config.API}/match/${params.id}/weather`;
        else if (resource === 'match-lineup' || resource === 'match-events' || resource === 'team-results')
            url = `${Config.API}/match/${params.id}`;
        else if (resource === 'player-active')
            url = `${Config.API}/player/${params.id}`;
        else if (resource === 'coach-active')
            url = `${Config.API}/coach/${params.id}`;
        else if (resource === 'referee-active')
            url = `${Config.API}/referee/${params.id}`;
        else if (resource === 'rounds')
            url = `${Config.API}/competition-season/${params.id}`;
        else if (resource === 'competition-season' && params.code && params.year) {
            url = `${Config.API}/competition-season/${params.code}/${params.year}`;
        }
        else if (resource === 'competition-season' && params.competitionId && params.seasonId) {
            const { data } = await httpClient(`${Config.API}/competition-season/${await getCompetitionCode(params.competitionId)}/${await getSeasonYear(params.seasonId)}`)
                .then(({ json }) => ({ 
                    data: json 
                }));
            url = `${Config.API}/${resource}/${data.id}`;
        }
        else if (resource === 'stat/players') {
            const ids = params.id.split(';');
            url = `${Config.API}/stat/player-breakdown?competitionSeasonId=${ids[0]}&playerId=${ids[1]}&sortBy=date&sortOrder=DESC`;
            return httpClient(url)
                .then(({ json }) => ({ 
                    data: { ...json, id: params.id }
                }));
        }
        else if (resource === 'stat/teams') {
            const ids = params.id.split(';');
            url = `${Config.API}/stat/team-breakdown?competitionSeasonId=${ids[0]}&teamId=${ids[1]}`;
            return httpClient(url)
                .then(({ json }) => ({ 
                    data: { ...json, id: params.id }
                }));
        }

        return httpClient(url)
            .then(({ json }) => ({ 
                data: json 
            }));
    },

    create: async (resource, params) => {
        let payload = params.data;
        if (resource === 'match' || resource === 'competition-season') {
            payload = {
                ...payload,
                competitionCode: await getCompetitionCode(params.data.competitionId),
                seasonYear: await getSeasonYear(params.data.seasonId)
            };
        }

        return httpClient(`${Config.API}/${resource}`, {
                method: 'POST',
                body: JSON.stringify(payload),
            })   
            .then(({ json }) => ({
                data: { ...params.data, id: json.id }
            }));
    },

    update: (resource, params) => {
        let url = `${Config.API}/${resource}/${params.id}`;
        let payload = params.data;

        if (resource === 'match') {
            payload = {
                startDateTime: payload.startDateTime ? payload.startDateTime : null,
                pool: payload.pool ? payload.pool : null,
                venueId: payload.venue ? payload.venue.id : null,
                neutralVenue: payload.venue ? payload.venue.isNeutral : null,
                homeTeamId: payload.homeTeam ? payload.homeTeam.id : null,
                awayTeamId: payload.awayTeam ? payload.awayTeam.id : null,
                refereeId: payload.referee ? payload.referee.id : null,
                extraTime: payload.extraTime ? payload.extraTime : null,
                attended: payload.attended ? payload.attended : null,
                status: payload.status
            };
        }
        else if (resource === 'venue') {
            payload = {
                name: payload.name,
                nickname: payload.nickname,
                venueLocationId: payload.location.id,
                weatherLocationId:  payload.weatherLocation ? payload.weatherLocation.id : null
            };
        }
        else if (resource === 'match-lineup') {
            url = `${Config.API}/match/${params.id}/lineup`;
            payload = payload.lineup;
            if (payload.substitute.playerId === null || payload.substitute.playerId === '00000000-0000-0000-0000-000000000000') {
                payload.substitute = {
                    number: 0,
                    location: 'Undefined',
                    playerId: '00000000-0000-0000-0000-000000000000',
                    captain: false,
                    didNotPlay: false
                };
            }
        }
        else if (resource === 'match-events') {
            url = `${Config.API}/match/${params.id}/add-event`;
            payload = payload.addEvent;
        }
        else if (resource === 'match-events-move') {
            url = `${Config.API}/match/${params.id}/move-event-${payload.moveUp ? 'up' : 'down'}`;
            payload = {
                sequence: params.previousData.sequence
            };
        }
        else if (resource === 'rounds') {
            url = `${Config.API}/competition-season/${params.id}/round`;
            payload = payload.addRound;
        }

        return httpClient(url, {
                method: 'PUT',
                body: JSON.stringify(payload),
            })
            .then(() => ({ 
                data: params.data 
            }));
    },

    delete: (resource, params) => {
        let url = `${Config.API}/${resource}/${params.id}`;
        let payload = params.data;
        let method = 'DELETE';

        if (resource === 'match-events') {
            url = `${Config.API}/match/${params.id}/remove-event`;
            payload = {
                sequence: params.previousData.sequence
            };
            method = 'PUT';
        }

        return httpClient(url, {
                method: method,
                body: JSON.stringify(payload),
            })
            .then(() => ({ 
                data: params.data 
            }));
    }
};

export default dataProvider;