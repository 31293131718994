import * as React from "react";
import { 
    List, Show, Edit, Create, 
    Datagrid, SimpleShowLayout, SimpleForm, Pagination,
    TextField,
    TextInput,
    required, useNotify, useRedirect, useShowController
} from 'react-admin';
import DateTimeField from '../components/DateTimeField';

export const VenueLocationList = props => {
    const ListPagination = (props) => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />;
    
    return (
        <List {...props} bulkActionButtons={false} pagination={<ListPagination />} perPage={25}>
            <Datagrid rowClick="show">
                <TextField source="city" label="City" />
                <TextField source="state" label="State" />
                <TextField source="country" label="Country" />
            </Datagrid>
        </List>
    );
};

export const VenueLocationShow = props => {
    const { loading } = useShowController(props);
    if (loading) return null;

    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="id" label="ID" />
                <TextField source="city" label="City" />
                <TextField source="state" label="State" />
                <TextField source="country" label="Country" />
                <DateTimeField source="createdUtc" label="Created" />
                <DateTimeField source="lastUpdatedUtc" label="Last Updated" />
            </SimpleShowLayout>
        </Show>
    );
};

export const VenueLocationEdit = props => (
    <Edit {...props} mutationMode="optimistic">
        <SimpleForm>
            <TextInput source="id" label="ID" fullWidth={true} disabled />
            <TextInput source="city" label="City" fullWidth={true} validate={[required()]} />
            <TextInput source="state" label="State" fullWidth={true} />
            <TextInput source="country" label="Country" fullWidth={true} validate={[required()]} />
        </SimpleForm>
    </Edit>
);

export const VenueLocationCreate = props => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = ({ data }) => {
		notify(`Venue Location '${data.name}' successfully created.`);
		redirect(`/venue-location/${data.id}/show`);
	};

    return (
        <Create onSuccess={onSuccess} {...props}>
            <SimpleForm>
                <TextInput source="city" label="City" fullWidth={true} validate={[required()]} />
                <TextInput source="state" label="State" fullWidth={true} />
                <TextInput source="country" label="Country" fullWidth={true} validate={[required()]} />
            </SimpleForm>
        </Create>
    );
};