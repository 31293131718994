import * as React from "react";
import { 
    List, Show, Edit, Create, 
    Datagrid, SimpleList, SimpleShowLayout, SimpleForm, Pagination,
    TextField,
    TextInput, ReferenceInput, SelectInput,
    required, useNotify, useRedirect, useShowController
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import DateTimeField from '../components/DateTimeField';

export const VenueList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const ListPagination = (props) => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />;

    return (
        <List {...props} bulkActionButtons={false} pagination={<ListPagination />} perPage={25}>
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.name}
                    secondaryText={record => `${record.city}, ${record.state === null ? '' : record.state + ', '}${record.country}`}
                    linkType="show"
                />
            ) : (
                <Datagrid rowClick="show">
                    <TextField source="name" label="Name" />
                    <TextField source="nickname" label="Nickname" />
                    <TextField source="city" label="City" />
                    <TextField source="state" label="State" />
                    <TextField source="country" label="Country" />
                    <TextField source="weatherLocation" label="Weather Location" />
                </Datagrid>
            )}
        </List>
    );
};

export const VenueShow = props => {
    const { loading } = useShowController(props);
    if (loading) return null;

    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="id" label="ID" />
                <TextField source="name" label="Name" />
                <TextField source="nickname" label="Nickname" />
                <TextField source="location.city" label="City" />
                <TextField source="location.state" label="State" />
                <TextField source="location.country" label="Country" />
                <TextField source="weatherLocation.name" label="Weather Location" />
                <DateTimeField source="createdUtc" label="Created" />
                <DateTimeField source="lastUpdatedUtc" label="Last Updated" />
            </SimpleShowLayout>
        </Show>
    );
};

export const VenueEdit = props => (
    <Edit {...props} mutationMode="optimistic">
        <SimpleForm>
            <TextInput source="id" label="ID" fullWidth={true} disabled />
            <TextInput source="name" label="Name" fullWidth={true} validate={[required()]} />
            <TextInput source="nickname" label="Nickname" fullWidth={true} validate={[required()]} />
            <ReferenceInput label="Venue Location" source="location.id" reference="venue-location" perPage={1000} fullWidth={true} validate={[required()]}>
                <SelectInput optionText={record => `${record.city}, ${record.state === null ? '' : record.state + ', '}${record.country}`} />
            </ReferenceInput>
            <ReferenceInput label="Weather Location" source="weatherLocation.id" reference="weather-location" perPage={1000} fullWidth={true}>
                <SelectInput optionText="name" />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);

export const VenueCreate = props => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = ({ data }) => {
		notify(`Venue '${data.name}' successfully created.`);
		redirect(`/venue/${data.id}/show`);
	};

    return (
        <Create onSuccess={onSuccess} {...props}>
            <SimpleForm>
                <TextInput source="name" label="Name" fullWidth={true} validate={[required()]} />
                <TextInput source="nickname" label="Nickname" fullWidth={true} validate={[required()]} />
                <ReferenceInput label="Venue Location" source="venueLocationId" reference="venue-location" perPage={1000} fullWidth={true} validate={[required()]}>
                    <SelectInput optionText={record => `${record.city}, ${record.state === null ? '' : record.state + ', '}${record.country}`} />
                </ReferenceInput>
                <ReferenceInput label="Weather Location" source="weatherLocationId" reference="weather-location" perPage={1000} fullWidth={true}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    );
};