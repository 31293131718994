import * as React from "react";
import { 
    List, Show, Create, Edit,
    Datagrid, TabbedShowLayout, SimpleShowLayout, Tab, SimpleList, SimpleForm, Pagination, Filter, Toolbar, TopToolbar,
    TextField, DateField, FunctionField, ArrayField, BooleanField,
    ReferenceInput, SelectInput, DateTimeInput, NumberInput, TextInput, BooleanInput, RadioButtonGroupInput,
    EditButton, DeleteButton, SaveButton, ShowButton, ListButton, Button, Confirm, Labeled,
    required, useNotify, useRedirect, useRefresh, useShowController, useGetOne, useGetList, useUpdate, useDelete, useRecordContext, useQuery
} from 'react-admin';
import { useFormState } from 'react-final-form';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Config from "../providers/configProvider";
import LogoField from '../components/LogoField';
import MatchStatusField from '../components/MatchStatusField';
import MatchStatusSelect from '../components/MatchStatusSelect';
import TimeWithZoneField from '../components/TimeWithZoneField';
import PositionLocationSelect from '../components/PositionLocationSelect';

import AddEventIcon from '@material-ui/icons/Sports';
import RemoveEventIcon from '@material-ui/icons/Delete';
import MoveEventUpIcon from '@material-ui/icons/KeyboardArrowUp';
import MoveEventDownIcon from '@material-ui/icons/KeyboardArrowDown';
import UpdateLineupIcon from '@material-ui/icons/Group';
import IncrementStatusIcon from '@material-ui/icons/Timer';

const useStyles = makeStyles(
    {
        tertiary: {
            opacity: '0.875',
            fontSize: '15px'
        }
    },
    {
        name: 'RaSimpleList'
    }
);

const getNumberWithOrdinal = (n,textOnly) => {
    const s = ["th", "st", "nd", "rd"];
    const v = n % 100;
    const suffix = s[(v - 20) % 10] || s[v] || s[0];

    if (textOnly) return n + suffix;

    return <span>{n}<span style={{verticalAlign:'top',fontSize:'.75em'}}>{suffix}</span></span>;
};

const getScoreTypeSingular = (scoreType) => {
    if (scoreType === 'Tries') return 'Try';
    else if (scoreType === 'Conversions') return 'Conversion';
    else if (scoreType === 'Penalty Goals') return 'Penalty Goal';
    else if (scoreType === 'Goals') return 'Goal';
    else if (scoreType === 'Field Goals 1') return '1 Point Field Goal';
    else if (scoreType === 'Field Goals 2') return '2 Point Field Goal';
    else return scoreType;
}

const getTeamScores = (teamScores, isSmall) => {
    if (teamScores.points === 0) return '';

    let scores = '';
    if (teamScores.tries > 0) scores += `, ${teamScores.tries} tr${teamScores.tries > 1 ? 'ies' : 'y'}`;
    if (teamScores.goals > 0) scores += `, ${teamScores.goals} goal${teamScores.goals > 1 ? 's' : ''}`;
    if (teamScores.fieldGoals1 > 0) scores += `, ${teamScores.fieldGoals1} field goal${teamScores.fieldGoals1 > 1 ? 's' : ''}`;
    if (teamScores.fieldGoals2 > 0) scores += `, ${teamScores.fieldGoals2} field goal${teamScores.fieldGoals2 > 1 ? 's' : ''} (2pt)`;

    if (isSmall && scores.length > 32) {
        scores = scores.replace('field goals (2pt)', 'fg2').replace('field goal (2pt)', 'fg2');
        scores = scores.replace('field goals', 'fg1').replace('field goal', 'fg1');
        scores = scores.replace('goals', 'g').replace('goal', 'g');
        scores = scores.replace('tries', 't').replace('try', 't');
    }

    return scores.substring(2);
}

const UpdateToolbar = (props) => (
    <Toolbar {...props}>
        <SaveButton
            label="Save"
            redirect="show"
            submitOnEnter={true}
        />
        <div style={{flex:1}}></div>
        <ShowButton
            label="Show"
            basePath="/match"
        />
    </Toolbar>
);

export const MatchList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const classes = useStyles(props);

    const MatchFilter = props => {
        const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
        return (
            <Filter {...props} style={{marginTop: isSmall ? '0px' : '-16px'}}>
                <ReferenceInput label="Season" source="seasonId" reference="season" perPage={1000} alwaysOn>
                    <SelectInput optionText="year" />
                </ReferenceInput>
                <ReferenceInput label="Team" source="teamId" reference="team" perPage={1000} alwaysOn={!isSmall}>
                    <SelectInput optionText={<LogoField type="teams" source="code" text="name" />} />
                </ReferenceInput>
                <ReferenceInput label="Opponent" source="opponentId" reference="team" perPage={1000} alwaysOn={!isSmall}>
                    <SelectInput optionText={<LogoField type="teams" source="code" text="name" />} />
                </ReferenceInput>
                <ReferenceInput label="Player" source="playerId" reference="player" perPage={1000} alwaysOn={!isSmall}>
                    <SelectInput optionText={record => `${record.lastName}, ${record.firstName}`} />
                </ReferenceInput>
                <ReferenceInput label="Coach" source="coachId" reference="coach" perPage={1000} alwaysOn={!isSmall}>
                    <SelectInput optionText={record => `${record.lastName}, ${record.firstName}`} />
                </ReferenceInput>
                <ReferenceInput label="Referee" source="refereeId" reference="referee" perPage={1000} alwaysOn={!isSmall}>
                    <SelectInput optionText={record => `${record.lastName}, ${record.firstName}`} />
                </ReferenceInput>
            </Filter>
        );
    };

    const ListRowStyle = (record, index) => ({
        backgroundColor: record ? (record.extraTime === true ? '#f9f5de' 
            : (record.filteredByTeam === false && record.filteredByPlayer === false && record.filteredByCoach === false && record.filteredByReferee === false && (Config.MyTeamCodes.includes(record.homeTeamCode) || Config.MyTeamCodes.includes(record.awayTeamCode)) ? '#efe'
            : (new Date(record.startDateTime).toLocaleDateString('sv-SE') === new Date().toLocaleDateString('sv-SE') ? '#f0f0f0' : '#fff'))) : '#fff',
        borderBottomWidth: '3px',
        borderBottomStyle: record && record.filteredByTeam === false && record.filteredByPlayer === false && record.filteredByCoach === false && record.filteredByReferee === false && record.firstMatchInRound ? 'double' : 'unset'
    });

    const ListPagination = (props) => <Pagination rowsPerPageOptions={[24, 32, 40, 100]} {...props} />;

    return (
        <List {...props} bulkActionButtons={false} filters={<MatchFilter />} filterDefaultValues={{ seasonId: Config.CurrentSeasonId }} pagination={<ListPagination />} perPage={24}>
            {isSmall ? (
                <SimpleList
                    primaryText={record => <span>{record.roundName}{record.pool ? ` (${record.pool})` : ''} - {record.status}</span>}
                    secondaryText={record => (
                        <div>
                            <div>
                                <span style={{display:'inline-block',width:'150px'}}><LogoField type="teams" source="homeTeamCode" text={record.competitionCode === 'NRL' ? 'homeTeamNickname' : 'homeTeamName'} /></span>
                                <span style={{display:'inline-block',width:'30px',textAlign:'right',fontWeight: record.status === 'Full Time' && record.homeTeamScore > record.awayTeamScore ? 'bold' : 'inherit'}}>{record.homeTeamScore}</span>
                            </div>
                            {record.isBye ? null : (
                                <div>
                                    <span style={{display:'inline-block',width:'150px'}}><LogoField type="teams" source="awayTeamCode" text={record.competitionCode === 'NRL' ? 'awayTeamNickname' : 'awayTeamName'} /></span>
                                    <span style={{display:'inline-block',width:'30px',textAlign:'right',fontWeight: record.status === 'Full Time' && record.awayTeamScore > record.homeTeamScore ? 'bold' : 'inherit'}}>{record.awayTeamScore}</span>
                                </div>
                            )}
                        </div>
                    )}
                    tertiaryText={record => record.status === 'Pre Game'
                        ? new Date(record.startDateTime).toLocaleString("en-US", { weekday: 'short', hour: 'numeric', minute: 'numeric' })
                        : `${record.venueName && record.venueName.length > 25 ? record.venueNickname : record.venueName}${record.neutralVenue ? ' (N)' : ''}` }
                    linkType={(record, id) => record.isBye ? false : 'show'}
                    classes={classes}
                    rowStyle={ListRowStyle}
                />
            ) : (
                <Datagrid rowClick={(id, basePath, record) => record.isBye ? null : 'show'} rowStyle={ListRowStyle}>
                    <LogoField type="competitions" source="competitionCode" text="competitionCode" label="Competition" />
                    <TextField source="seasonYear" label="Season" />
                    <FunctionField render={record => `${record.roundName}${record.pool ? ` (${record.pool.length <= 2 ? "Pool " : ""}${record.pool})` : ''}`} label="Round" />
                    <MatchStatusField source="status" label="Status" />
                    <FunctionField render={record => record.competitionCode === 'NRL' 
                        ? <LogoField type="teams" source="homeTeamCode" text="homeTeamNickname" /> 
                        : <LogoField type="teams" source="homeTeamCode" text="homeTeamName" />
                    } label="Home Team" />
                    <FunctionField render={record => <span style={{fontWeight: record.status === 'Full Time' && record.homeTeamScore > record.awayTeamScore ? 'bold' : 'inherit'}}>{record.homeTeamScore}</span>} label="" />
                    <FunctionField render={record => record.isBye ? null : (record.competitionCode === 'NRL' 
                        ? <LogoField type="teams" source="awayTeamCode" text="awayTeamNickname" /> 
                        : <LogoField type="teams" source="awayTeamCode" text="awayTeamName" />)
                    } label="Away Team" />
                    <FunctionField render={record => <span style={{fontWeight: record.status === 'Full Time' && record.awayTeamScore > record.homeTeamScore ? 'bold' : 'inherit'}}>{record.awayTeamScore}</span>} label="" />
                    <FunctionField render={record => <span>{record.venueName}{record.neutralVenue ? ' (N)' : ''}</span>} label="Venue" />
                    <DateField source="startDateTime" label="Date" options={{ weekday: 'short', day: 'numeric', month: 'long' }} locales="en-AU" />
                    <TimeWithZoneField source="startDateTime" includeDate={false} label="Time" />
                </Datagrid>
            )}
        </List>
    );
};

export const MatchShow = props => {
    const { basePath, ...rest } = props;
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const classes = useStyles(props);
    const { record, loading } = useShowController(props);

    const { data: competitionSeason, loading: compSeasonLoading } = useQuery({ 
        type: 'getOne',
        resource: 'competition-season',
        payload: { 
            code: record ? (record.competition ? record.competition.code : record.competitionCode) : 'NRL',
            year: record ? (record.season ? record.season.year : record.seasonYear) : new Date().getFullYear()
        }
    });

    if (loading || compSeasonLoading) return null;

    const hasLineUps = (record.homeTeam.players && record.homeTeam.players.length > 0) || (record.awayTeam.players && record.awayTeam.players.length > 0);

    const MatchWeather = () => {
        const { data, loading, error } = useGetOne('match-weather', props.id);
        const style = { display: 'inline-block', width: '120px' };
        let content = '';
        if (loading)
            content = 'Loading...';
        else if (error)
            content = 'No weather available';
        else
            content = (
                <div>
                    <div><span style={style}>Location:</span>{data.observation.locationName}</div>
                    <div><span style={style}>Temperature:</span>{data.observation.temperatureCelcius} °C</div>
                    <div><span style={style}>Rain since 9am:</span>{data.observation.rainMillimetres} mm</div>
                    <div><span style={style}>Wind:</span>{data.observation.windSpeedKmH} km/h {data.observation.windDirection}</div>
                </div>
            );

        return (
            <Labeled label="Weather">
                <Typography component="span" variant="body2">
                    {content}
                </Typography>
            </Labeled>
        );
    };

    const MatchEvents = ({ competitionCode }) => {
        const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
        const { data, loaded } = useGetOne('match-event-list', props.id);
        if (!loaded) return <span>Loading</span>;

        const RemoveEventButton = () => {
            const record = useRecordContext();
            const [open, setOpen] = React.useState(false);
            const [deleteOne, { loading, error }] = useDelete();
            const notify = useNotify();
            const refresh = useRefresh();

            const handleClick = () => setOpen(true);
            const handleDialogClose = () => setOpen(false);
        
            const handleConfirm = () => {
                deleteOne('match-events', props.id, record);
                setOpen(false);
                notify(`Match event successfully deleted.`);
		        refresh();
            };
            if (error) { return <p>ERROR</p>; }

            return (
                <React.Fragment>
                    <Button label="Remove" children={<RemoveEventIcon />} disabled={loading} onClick={handleClick} style={isSmall ? {padding:'0',color:'red'}: {color:'red'}} />
                    <Confirm
                        isOpen={open}
                        loading={loading}
                        title="Are you sure you want to remove this event?"
                        content="This action cannot be undone."
                        onConfirm={handleConfirm}
                        onClose={handleDialogClose}
                    />
                </React.Fragment>
            );
        };

        const MoveEventButtons = () => {
            const record = useRecordContext();
            const [update, { loading, error }] = useUpdate();
            const refresh = useRefresh();
        
            const handleClick = (moveUp) => {
                update('match-events-move', props.id, { moveUp: moveUp }, record);
		        refresh();
            };
            if (error) { return <p>ERROR</p>; }

            return (
                <React.Fragment>
                    <Button label="Move Up" children={<MoveEventUpIcon />} disabled={loading} onClick={() => handleClick(true)} style={isSmall ? {padding:'0 0 0 4px'}: {}} /> 
                    <Button label="Move Down" children={<MoveEventDownIcon />} disabled={loading} onClick={() => handleClick(false)} style={isSmall ? {padding:'0'}: {}} />
                </React.Fragment>
            );
        };

        return (
            <ArrayField record={data} source="events" addLabel={false}>
                {isSmall ? (
                    <SimpleList
                        primaryText={record => `${getNumberWithOrdinal(record.minute, true)} min - ${getScoreTypeSingular(record.scoreType)}`}
                        secondaryText={record=> <span><LogoField type="teams" source="teamCode" text={competitionCode === "NRL" ? "teamNickname" : "teamName"} additionalText={record.playerId === null ? '' : `- ${record.playerFirstName} ${record.playerLastName}`} /></span>}
                        tertiaryText={record => <span>{record.homeTeamScore} - {record.awayTeamScore} <MoveEventButtons /> <RemoveEventButton /></span>}
                        rowStyle={() => ({
                            padding: '0',
                            display: 'unset'
                        })}
                        style={{padding:0}}
                        classes={classes}
                        linkType={false}
                    />
                ) : (
                    <Datagrid>
                        <FunctionField render={record => getNumberWithOrdinal(record.minute, false)} label="Minute" />
                        <LogoField type="teams" source="teamCode" text={competitionCode === "NRL" ? "teamNickname" : "teamName"} label="Team" />
                        <FunctionField render={record =>
                            record.playerId === null ? '-' : <LogoField type="players" source="playerId" text="playerFirstName" extension="png" fallback="player.png" width={30} additionalText={record.playerLastName} />
                        } label="Player" />
                        <FunctionField render={record => getScoreTypeSingular(record.scoreType)} label="Event" />
                        <FunctionField render={record => `${record.homeTeamScore} - ${record.awayTeamScore}`} label="Score" />
                        <React.Fragment>
                            <MoveEventButtons /> 
                            <RemoveEventButton />
                        </React.Fragment>
                    </Datagrid>
                )}
            </ArrayField>
        );
    };

    const IncrementStatusButton = ({ record }) => {
        const [update, { loading, error }] = useUpdate();
        const refresh = useRefresh();

        const isFullTime = record.status === 'Full Time';
        const areScoresLevel = record.homeTeam.scores.points === record.awayTeam.scores.points;

        let matchStatus = record.status;
        if (matchStatus === 'Pre Game') matchStatus = 'First Half';
        else if (matchStatus === 'First Half') matchStatus = 'Half Time';
        else if (matchStatus === 'Half Time') matchStatus = 'Second Half';
        else if (matchStatus === 'Second Half' && areScoresLevel) matchStatus = 'Extra Time';
        else if (matchStatus === 'Second Half' || matchStatus === 'Extra Time') matchStatus = 'Full Time';

        const handleClick = () => {
            update('match', record.id, { status: matchStatus }, record);
            refresh();
        }
        if (error) { return <p>ERROR</p>; }

        return <Button label="Increment Status" children={<IncrementStatusIcon />} disabled={loading} onClick={handleClick} style={{display: isFullTime ? 'none' : 'inherit'}} />;
    };

    const Actions = ({ data }) => (
        <TopToolbar {...rest}>
            <ListButton
                label="Match List"
                basePath={basePath}
            />
            <EditButton
                label="Edit Match"
                basePath={basePath}
                record={data} />
            <EditButton
                label="Edit Line-ups"
                icon={<UpdateLineupIcon />}
                basePath="/match-lineup"
                record={data} />
            <IncrementStatusButton
                record={data} />
            <EditButton
                label="Add Event"
                icon={<AddEventIcon />}
                basePath="/match-events"
                record={data} />
            <DeleteButton 
                label="Delete"
                basePath={basePath}
                record={data}
                mutationMode="optimistic"
                confirmTitle="Are you sure you want to delete this match?"
                confirmContent="This action cannot be undone." />
        </TopToolbar>
    );

    const Title = ({ record }) => <span>Match - {record.competition.code} {record.season.year} - {record.round.name} - {record.competition.code === 'NRL'
        ? record.homeTeam.nickname : record.homeTeam.name} v {record.competition.code === 'NRL'
        ? record.awayTeam.nickname : record.awayTeam.name}</span>;

    return (
        <Show title={<Title />} actions={<Actions />} {...props}>
            <React.Fragment>
                <SimpleShowLayout>
                    <FunctionField render={record => <LogoField type="competitions" source="competition.code" text="competition.code" additionalText={` ${record.season.year} - ${record.round.name}${record.pool ? ` (${record.pool.length <= 2 ? "Pool " : ""}${record.pool})` : ''}`}/>} label="Round" />
                    <MatchStatusField source="status" extraTimeSource="extraTime" label="Status" />
                    <FunctionField render={record => (
                        <div style={{fontFamily:'Digital-7',fontSize:'24px'}}>
                            <span style={{display:'inline-block',width:145}}>
                                <img src={`images/teams/${record.homeTeam.code}.svg`} onError={(e)=>{e.target.onError=null;e.target.src='images/BADGE.svg'}} alt={record.homeTeam.code} style={{height:30,margin:'0 4px 0 0',verticalAlign:'middle',objectFit:'contain'}} />
                                {record.competition.code === 'NRL' ? record.homeTeam.nickname : (record.homeTeam.name.length > 9 ? `${record.homeTeam.name.substring(0,9)}..` : record.homeTeam.name)}
                            </span>
                            <span style={{display:'inline-block',width:35,textAlign:'right'}}>{record.homeTeam.scores.points}</span>
                            <span style={{display:'inline-block',textAlign:'left',fontSize:'16px',paddingLeft:'16px'}}>{getTeamScores(record.homeTeam.scores, isSmall)}</span>
                        </div>
                    )} label="Score" />   
                    <FunctionField render={record => (
                        <div style={{fontFamily:'Digital-7',fontSize:'24px'}}>
                            <span style={{display:'inline-block',width:145}}>
                                <img src={`images/teams/${record.awayTeam.code}.svg`} onError={(e)=>{e.target.onError=null;e.target.src='images/BADGE.svg'}} alt={record.awayTeam.code} style={{height:30,margin:'0 4px 0 0',verticalAlign:'middle',objectFit:'contain'}} />
                                {record.competition.code === 'NRL' ? record.awayTeam.nickname : (record.awayTeam.name.length > 9 ? `${record.awayTeam.name.substring(0,9)}..` : record.awayTeam.name)}
                            </span>
                            <span style={{display:'inline-block',width:35,textAlign:'right'}}>{record.awayTeam.scores.points}</span>
                            <span style={{display:'inline-block',textAlign:'left',fontSize:'16px',paddingLeft:'16px'}}>{getTeamScores(record.awayTeam.scores, isSmall)}</span>
                        </div>
                    )} addLabel={false} />
                </SimpleShowLayout>
                <br/>
                <TabbedShowLayout>
                    <Tab label="Summary">
                        <TextField source="id" label="ID" />
                        <TimeWithZoneField source="startDateTime" includeDate={true} label="Kick-Off" />
                        <FunctionField render={record => `${record.venue.name} (${record.venue.location.city}, ${record.venue.location.state === null ? '' : record.venue.location.state + ', '}${record.venue.location.country})${record.venue.isNeutral ? ' - Neutral' : ''}`} label="Venue" />
                        <MatchWeather />
                        <FunctionField render={record => record.referee === null ? '-' : `${record.referee.firstName} ${record.referee.lastName}`} label="Referee" />
                        <BooleanField source="extraTime" label="Extra Time?" />
                        <BooleanField source="attended" label="Attended?" />
                    </Tab>
                    <Tab label="Events">
                        <FunctionField render={record => <MatchEvents competitionCode={record.competition.code} />} addLabel={false} />
                    </Tab>
                    {hasLineUps && (
                        <Tab label="Line-ups">
                            <FunctionField render={record => record.homeTeam.players && record.homeTeam.players.length > 0 ? (
                                <React.Fragment>
                                    <FunctionField render={record => record.competition.code === 'NRL'
                                        ? <LogoField type="teams" source="homeTeam.code" text="homeTeam.nickname" />
                                        : <LogoField type="teams" source="homeTeam.code" text="homeTeam.name" />
                                    } addLabel={false} />
                                    <ArrayField source="homeTeam.players" addLabel={false}>
                                        <SimpleList classes={classes}
                                            primaryText={record => `${record.number}. ${record.player.firstName} ${record.player.lastName} ${record.captain ? ' (c)' : ''}`}
                                            secondaryText={record => `${record.position.name} ${record.position.location && record.position.location !== 'Undefined' ? `(${record.position.location})` : ''}`}
                                            tertiaryText={record => <LogoField type="players" extension="png" height={42} source="player.id" fallback="player.png" />}
                                            rowStyle={record => ({
                                                borderBottomStyle: record.position.code === 'L' ? 'groove' : 'inherit',
                                                borderBottomWidth: record.position.code === 'L' ? '1px' : 'inherit',
                                                padding: '0',
                                                display: 'inherit'
                                            })}
                                            style={{padding:0}}
                                            linkType={(record, id) => `/stat/players/${competitionSeason.id};${record.player.id}/show`}
                                        />
                                    </ArrayField>
                                    <FunctionField render={record => record.homeTeam.coach ? (
                                        <ArrayField record={{coach:[record.homeTeam.coach]}} source="coach" addLabel={false}>
                                            <SimpleList classes={classes}
                                                primaryText={record => `${record.firstName} ${record.lastName}`}
                                                secondaryText={record => 'Head Coach'}
                                                tertiaryText={record => <LogoField type="coaches" extension="png" height={42} source="id" fallback="coach.png" />}
                                                rowStyle={record => ({
                                                    borderTopStyle: 'groove',
                                                    borderTopWidth: '1px',
                                                    padding: '0',
                                                    display: 'inherit'
                                                })}
                                                style={{padding:0}}
                                                linkType={(record, id) => `/coach/${record.id}/show`}
                                            />
                                        </ArrayField>
                                    ) : null} addLabel={false} />
                                </React.Fragment>
                            ) : null} addLabel={false} />
                            <FunctionField render={record => record.awayTeam.players && record.awayTeam.players.length > 0 ? (
                                <React.Fragment>
                                    <FunctionField render={record => record.competition.code === 'NRL'
                                        ? <LogoField type="teams" source="awayTeam.code" text="awayTeam.nickname" />
                                        : <LogoField type="teams" source="awayTeam.code" text="awayTeam.name" />
                                    } addLabel={false} />
                                    <ArrayField source="awayTeam.players" addLabel={false}>
                                        <SimpleList classes={classes}
                                            primaryText={record => `${record.number}. ${record.player.firstName} ${record.player.lastName} ${record.captain ? ' (c)' : ''}`}
                                            secondaryText={record => `${record.position.name} ${record.position.location && record.position.location !== 'Undefined' ? `(${record.position.location})` : ''}`}
                                            tertiaryText={record => <LogoField type="players" extension="png" height={42} source="player.id" fallback="player.png" />}
                                            rowStyle={record => ({
                                                borderBottomStyle: record.position.code === 'L' ? 'groove' : 'inherit',
                                                borderBottomWidth: record.position.code === 'L' ? '1px' : 'inherit',
                                                padding: '0',
                                                display: 'inherit'
                                            })}
                                            style={{padding:0}}
                                            linkType={(record, id) => `/stat/players/${competitionSeason.id};${record.player.id}/show`}
                                        />
                                    </ArrayField>
                                    <FunctionField render={record => record.awayTeam.coach ? (
                                        <ArrayField record={{coach:[record.awayTeam.coach]}} source="coach" addLabel={false}>
                                            <SimpleList classes={classes}
                                                primaryText={record => `${record.firstName} ${record.lastName}`}
                                                secondaryText={record => 'Head Coach'}
                                                tertiaryText={record => <LogoField type="coaches" extension="png" height={42} source="id" fallback="coach.png" />}
                                                rowStyle={record => ({
                                                    borderTopStyle: 'groove',
                                                    borderTopWidth: '1px',
                                                    padding: '0',
                                                    display: 'inherit'
                                                })}
                                                style={{padding:0}}
                                                linkType={(record, id) => `/coach/${record.id}/show`}
                                            />
                                        </ArrayField>
                                    ) : null} addLabel={false} />
                                </React.Fragment>
                            ) : null} addLabel={false} />
                        </Tab>
                    )}
                </TabbedShowLayout>
            </React.Fragment>
        </Show>
    );
}

const VenueTextRenderer = ({ record }) => (
    <div style={{lineHeight:1.25}}>
        <div>{record.name}</div>
        <div style={{fontSize:'.9em',color:'gray'}}>{record.nickname}</div>
    </div>
);

export const MatchEdit = props => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = ({ data }) => {
		notify(`Match successfully updated.`);
		redirect(`/match/${data.id}/show`);
	};

    const GetCompetitionSeason = () => {
        const record = useRecordContext();
        const { data, loading } = useQuery({ 
            type: 'getOne',
            resource: 'competition-season',
            payload: { 
                competitionId: record.competition.id ? record.competition.id : Config.NRLCompetitionId,
                seasonId: record.season.id ? record.season.id : Config.CurrentSeasonId
            }
        });
        return loading ? null : data;
    };

    const GetTeams = () => {
        const { data, loading } = useGetList('team', { page: 1, perPage: 1000 });
        return loading ? null : data;
    };

    const TeamSelectInput = ({ label, source }) => {
        const competitionSeason = GetCompetitionSeason();
        const teamList = GetTeams();

        if (!competitionSeason) return null;

        const teams = competitionSeason.teams.map(t => teamList[t.id]);

        if (competitionSeason.competition.code === 'NRL')
            teams.sort((a,b) => a.code === 'TBA' ? 2 : ((a.nickname > b.nickname) ? 1 : ((b.nickname > a.nickname) ? -1 : 0)));
        else
            teams.sort((a,b) => a.code === 'TBA' ? 2 : ((a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));
    
        return <SelectInput label={label} choices={teams} optionText={<LogoField type="teams" source="code" text={competitionSeason.competition.code === 'NRL' ? 'nickname' : 'name'} />} source={source} fullWidth={true} validate={[required()]} />;
    };

    const PoolTextInput = ({ label, source }) => {
        const record = useRecordContext();

        if (!Config.PoolCompetitionIds.includes(record.competition.id)) return null;
    
        return <TextInput label={label} source={source} fullWidth={true} />;
    };

    const Title = ({ record }) => <span>Update Match - {record.competition.code} {record.season.year} - {record.round.name} - {record.competition.code === 'NRL'
        ? record.homeTeam.nickname : record.homeTeam.name} v {record.competition.code === 'NRL'
        ? record.awayTeam.nickname : record.awayTeam.name}</span>;

    return (
        <Edit title={<Title />} onSuccess={onSuccess} mutationMode="pessimistic" {...props}>
            <SimpleForm toolbar={<UpdateToolbar />}>
                <TextInput disabled label="Id" source="id" />
                <MatchStatusSelect label="Status" source="status" />
                <DateTimeInput source="startDateTime" label="Date and Time" fullWidth={true} validate={[required()]} />
                <PoolTextInput label="Pool" source="pool" />
                <TeamSelectInput label="Home Team" source="homeTeam.id" />
                <TeamSelectInput label="Away Team" source="awayTeam.id" />
                <ReferenceInput label="Referee" source="referee.id" reference="referee-active" perPage={1000} fullWidth={true}>
                    <SelectInput optionText={record => `${record.lastName}, ${record.firstName}`} />
                </ReferenceInput>
                <ReferenceInput label="Venue" source="venue.id" reference="venue" perPage={1000} fullWidth={true} validate={[required()]}>
                    <SelectInput optionText={<VenueTextRenderer />} />
                </ReferenceInput>
                <BooleanInput label="Neutral Venue?" source="venue.isNeutral" />
                <BooleanInput label="Extra Time?" source="extraTime" />
                <BooleanInput label="Attended?" source="attended" />
            </SimpleForm>
        </Edit>
    );
};

export const MatchLineupEdit = props => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = ({ data }) => {
		notify(`Match line-up successfully updated.`);
		redirect(`/match/${data.id}/show/2`);
	};

    const TeamSelectInput = () => {
        const record = useRecordContext();
        const homeTeam = record.homeTeam.players.length > 0 ? true : (record.awayTeam.players.length > 0 ? false : null);
        return <RadioButtonGroupInput source="lineup.homeTeam" label="Team" defaultValue={homeTeam} choices={[
            { id: true, code: record.homeTeam.code, name: record.competition.code === 'NRL' ? record.homeTeam.nickname : record.homeTeam.name },
            { id: false, code: record.awayTeam.code, name: record.competition.code === 'NRL' ? record.awayTeam.nickname : record.awayTeam.name }
        ]} optionText={<LogoField type="teams" source="code" text="name" />} />;
    };

    const PositionInput = ({ number, label, source }) => {
        const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
        const record = useRecordContext();
        const homeTeam = record.homeTeam.players.length > 0 ? true : (record.awayTeam.players.length > 0 ? false : null);

        const player = homeTeam === true
            ? record.homeTeam.players[number - 1]
            : record.awayTeam.players[number - 1];

        const currentNumber = player ? player.number : number;
        const currentLocation = player ? (player.position ? player.position.location : 'Undefined') : null;
        const currentPlayerId = player ? player.player.id : null;
        const currentCaptain = player ? player.captain : false; 
        const currentDidNotPlay = player ? (player.position.id === Config.DNPPositionId ? true : false) : false;

        if (isSmall)
            return (
                <span>
                    <NumberInput label="Number" source={`lineup.${source}.number`} min={1} max={99} step={1} defaultValue={currentNumber} validate={source === 'substitute' ? null : [required()]} style={{width:'20%',margin:0}} />&nbsp;
                    <ReferenceInput label={label} source={`lineup.${source}.playerId`} reference="player-active" perPage={100} defaultValue={currentPlayerId} validate={source === 'substitute' ? null : [required()]} style={{width:'75%',margin:0}}>
                        <SelectInput optionText={record => `${record.lastName}, ${record.firstName}`} />
                    </ReferenceInput>
                    <br/>
                    <span style={{width:'20%',display:'inline-block'}}></span>&nbsp;<PositionLocationSelect label="Location" source={`lineup.${source}.location`} style={{width:'75%',margin:0}} defaultValue={currentLocation} validate={source === 'substitute' ? null : [required()]} />&nbsp;
                    {number ? <BooleanInput label="Captain" source={`lineup.${source}.captain`} defaultValue={currentCaptain} style={{margin:0}} /> : null }
                    {number > 13 ? <BooleanInput label="Did Not Play" source={`lineup.${source}.didNotPlay`} defaultValue={currentDidNotPlay} style={{margin:0}} /> : null }
                </span>
            );
        else
            return (
                <span>
                    <NumberInput label="Number" source={`lineup.${source}.number`} min={1} max={99} step={1} defaultValue={currentNumber} validate={source === 'substitute' ? null : [required()]} style={{margin:0}} />&nbsp;
                    <ReferenceInput label={label} source={`lineup.${source}.playerId`} reference="player-active" perPage={100} defaultValue={currentPlayerId} validate={source === 'substitute' ? null : [required()]} style={{width:500,margin:0}}>
                        <SelectInput optionText={record => `${record.lastName}, ${record.firstName}`} />
                    </ReferenceInput>&nbsp;
                    <PositionLocationSelect label="Location" source={`lineup.${source}.location`} style={{margin:0}} defaultValue={currentLocation} validate={source === 'substitute' ? null : [required()]} />&nbsp;
                    {number ? <RadioButtonGroupInput label="Captain" source={`lineup.${source}.captain`} defaultValue={currentCaptain} style={{margin:0}} choices={[
                        { id: true, name: 'Yes' },
                        { id: false, name: 'No' }
                    ]} /> : null}&nbsp;
                    {number > 13 ? <RadioButtonGroupInput label="Did Not Play" source={`lineup.${source}.didNotPlay`} defaultValue={currentDidNotPlay} style={{margin:0}} choices={[
                        { id: true, name: 'Yes' },
                        { id: false, name: 'No' }
                    ]} /> : null}
                </span>
            );
    };

    const CoachSelectInput = () => {
        const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
        const record = useRecordContext();
        const homeTeam = record.homeTeam.players.length > 0 ? true : (record.awayTeam.players.length > 0 ? false : null);
        
        const coach = homeTeam === true ? record.homeTeam.coach : record.awayTeam.coach;

        const currentCoachId = coach ? coach.id : null;

        return (
            <ReferenceInput label="Coach" source="lineup.coachId" reference="coach-active" perPage={100} defaultValue={currentCoachId} style={{width: isSmall ? '96%' : '594px', margin:0}}>
                <SelectInput optionText={record => `${record.lastName}, ${record.firstName}`} />
            </ReferenceInput>
        );
    };

    const Title = ({ record }) => <span>Update Match Line-ups - {record.competition.code} {record.season.year} - {record.round.name} - {record.competition.code === 'NRL'
        ? record.homeTeam.nickname : record.homeTeam.name} v {record.competition.code === 'NRL'
        ? record.awayTeam.nickname : record.awayTeam.name}</span>;

    return (
        <Edit title={<Title />} onSuccess={onSuccess} mutationMode="pessimistic" {...props}>
            <SimpleForm toolbar={<UpdateToolbar />}>
                <TextField label="ID" source="id" />
                <FunctionField render={record => <LogoField type="competitions" source="competition.code" text="competition.code" additionalText={` ${record.season.year} - ${record.round.name}`}/>} label="Round" />
                <FunctionField render={record => record.competition.code === 'NRL'
                    ? <span><LogoField type="teams" source="homeTeam.code" text="homeTeam.nickname" /> v <LogoField type="teams" location="right" source="awayTeam.code" text="awayTeam.nickname" /></span>
                    : <span><LogoField type="teams" source="homeTeam.code" text="homeTeam.name" /> v <LogoField type="teams" location="right" source="awayTeam.code" text="awayTeam.name" /></span>
                } label="Match" />
                <TimeWithZoneField source="startDateTime" includeDate={true} label="Kick-Off" />
                <TeamSelectInput />
                <PositionInput number="1" label="Fullback" source="fullback" />
                <PositionInput number="2" label="Wing 1" source="wing1" />
                <PositionInput number="3" label="Centre 1" source="centre1" />
                <PositionInput number="4" label="Centre 2" source="centre2" />
                <PositionInput number="5" label="Wing 2" source="wing2" />
                <PositionInput number="6" label="Five-Eighth" source="fiveEighth" />
                <PositionInput number="7" label="Halfback" source="halfback" />
                <PositionInput number="8" label="Prop 1" source="prop1" />
                <PositionInput number="9" label="Dummy Half" source="dummyHalf" />
                <PositionInput number="10" label="Prop 2" source="prop2" />
                <PositionInput number="11" label="Second Row 1" source="secondRow1" />
                <PositionInput number="12" label="Second Row 2" source="secondRow2" />
                <PositionInput number="13" label="Lock" source="lock" />
                <PositionInput number="14" label="Interchange 1" source="interchange1" />
                <PositionInput number="15" label="Interchange 2" source="interchange2" />
                <PositionInput number="16" label="Interchange 3" source="interchange3" />
                <PositionInput number="17" label="Interchange 4" source="interchange4" />
                <PositionInput label="Substitute" source="substitute" />
                <CoachSelectInput />
            </SimpleForm>
        </Edit>
    );
};

export const MatchEventAdd = props => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = ({ data }) => {
		notify(`Match event successfully added.`);
		redirect(`/match/${data.id}/show/1`);
	};

    const TeamSelectInput = () => {
        const record = useRecordContext();
        return <RadioButtonGroupInput source="addEvent.homeTeam" label="Team" defaultValue={true} fullWidth={true} validate={[required()]} choices={[
            { id: true, code: record.homeTeam.code, name: record.competition.code === 'NRL' ? record.homeTeam.nickname : record.homeTeam.name },
            { id: false, code: record.awayTeam.code, name: record.competition.code === 'NRL' ? record.awayTeam.nickname : record.awayTeam.name }
        ]} optionText={<LogoField type="teams" source="code" text="name" />} />;
    };

    const PlayerSelectInput = () => {
        const record = useRecordContext();
        const { values } = useFormState();
        const team = values.addEvent && values.addEvent.homeTeam === true ? record.homeTeam : (values.addEvent && values.addEvent.homeTeam === false ? record.awayTeam : null);
        if (team === null || team.players.length === 0) return null;
        return <SelectInput source="addEvent.playerId" label="Player" fullWidth={true} choices={team.players} optionValue="player.id" optionText={record => `${record.number}. ${record.player.firstName} ${record.player.lastName} ${record.captain ? ' (c)' : ''}`} />;
    };

    const GetCompetitionSeason = () => {
        const record = useRecordContext();
        const { data, loading } = useQuery({ 
            type: 'getOne',
            resource: 'competition-season',
            payload: { 
                competitionId: record.competition.id ? record.competition.id : Config.NRLCompetitionId,
                seasonId: record.season.id ? record.season.id : Config.CurrentSeasonId
            }
        });
        return loading ? null : data;
    };

    const GetScoreTypes = () => {
        const { data, loading } = useGetList('score-type', { page: 1, perPage: 1000 });
        return loading ? null : data;
    };

    const ScoreTypeSelectInput = ({ label, source }) => {
        const competitionSeason = GetCompetitionSeason();
        const scoreTypeList = GetScoreTypes();

        if (!competitionSeason) return null;

        const scoreTypes = competitionSeason.scoreTypes.map(st => scoreTypeList[st.id]);

        return <SelectInput label={label} choices={scoreTypes} optionText={record => getScoreTypeSingular(record.name)} source={source} fullWidth={true}  />;
    };

    const Title = ({ record }) => <span>Add Event - {record.competition.code} {record.season.year} - {record.round.name} - {record.competition.code === 'NRL'
        ? record.homeTeam.nickname : record.homeTeam.name} v {record.competition.code === 'NRL'
        ? record.awayTeam.nickname : record.awayTeam.name}</span>;

    return (
        <Edit title={<Title />} onSuccess={onSuccess} mutationMode="pessimistic" {...props}>
            <SimpleForm toolbar={<UpdateToolbar />}>
                <TextField label="ID" source="id" />
                <FunctionField render={record => <LogoField type="competitions" source="competition.code" text="competition.code" additionalText={` ${record.season.year} - ${record.round.name}`}/>} label="Round" />
                <FunctionField render={record => record.competition.code === 'NRL'
                    ? <span><LogoField type="teams" source="homeTeam.code" text="homeTeam.nickname" /> v <LogoField type="teams" location="right" source="awayTeam.code" text="awayTeam.nickname" /></span>
                    : <span><LogoField type="teams" source="homeTeam.code" text="homeTeam.name" /> v <LogoField type="teams" location="right" source="awayTeam.code" text="awayTeam.name" /></span>
                } label="Match" />
                <TimeWithZoneField source="startDateTime" includeDate={true} label="Kick-Off" />
                <TeamSelectInput />
                <NumberInput source="addEvent.minute" label="Minute" min={1} max={120} step={1} fullWidth={true} validate={[required()]} />
                <ScoreTypeSelectInput source="addEvent.scoreTypeId" label="Score Type" fullWidth={true} validate={[required()]} />
                <PlayerSelectInput />
            </SimpleForm>
        </Edit>
    );
};

export const MatchCreate = props => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = ({ data }) => {
		notify(`Match successfully created.`);
		redirect(`/match`);
	};

    const GetCompetitionSeason = (values) => {
        const { data, loading } = useQuery({ 
            type: 'getOne',
            resource: 'competition-season',
            payload: { 
                competitionId: values.competitionId ? values.competitionId : Config.NRLCompetitionId,
                seasonId: values.seasonId ? values.seasonId : Config.CurrentSeasonId
            }
        });
        return loading ? null : data;
    };

    const GetTeams = () => {
        const { data, loading } = useGetList('team', { page: 1, perPage: 1000 });
        return loading ? null : data;
    };

    const RoundSelectInput = ({ label, source }) => {
        const { values } = useFormState();
        const competitionSeason = GetCompetitionSeason(values);

        if (!competitionSeason) return null;
    
        return <SelectInput label={label} choices={competitionSeason.rounds} optionText="name" optionValue="number" source={source} fullWidth={true} validate={[required()]} />;
    };

    const PoolTextInput = ({ label, source }) => {
        const { values } = useFormState();

        if (!Config.PoolCompetitionIds.includes(values.competitionId)) return null;
    
        return <TextInput label={label} source={source} fullWidth={true} />;
    };

    const TeamSelectInput = ({ label, source }) => {
        const { values } = useFormState();
        const competitionSeason = GetCompetitionSeason(values);
        const teamList = GetTeams();

        if (!competitionSeason) return null;

        const teams = competitionSeason.teams.map(t => teamList[t.id]);
        if (competitionSeason.competition.code === 'NRL')
            teams.sort((a,b) => a.code === 'TBA' ? 2 : ((a.nickname > b.nickname) ? 1 : ((b.nickname > a.nickname) ? -1 : 0)));
        else
            teams.sort((a,b) => a.code === 'TBA' ? 2 : ((a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));
    
        return <SelectInput label={label} choices={teams} optionText={<LogoField type="teams" source="code" text={competitionSeason.competition.code === 'NRL' ? 'nickname' : 'name'} />} source={source} fullWidth={true} validate={[required()]} />;
    };

    return (
        <Create title="Create Match" onSuccess={onSuccess} {...props}>
            <SimpleForm>
                <ReferenceInput label="Competition" source="competitionId" reference="competition" perPage={100} fullWidth={true} validate={[required()]}>
                    <SelectInput optionText={<LogoField type="competitions" source="code" text="name" />} />
                </ReferenceInput>
                <ReferenceInput label="Season" source="seasonId" reference="season" perPage={100} fullWidth={true} validate={[required()]}>
                    <SelectInput optionText="year" />
                </ReferenceInput>
                <RoundSelectInput label="Round" source="roundNumber" />
                <PoolTextInput label="Pool" source="pool" />
                <DateTimeInput source="startDateTime" label="Date and Time" fullWidth={true} validate={[required()]} />
                <TeamSelectInput label="Home Team" source="homeTeamId" />
                <TeamSelectInput label="Away Team" source="awayTeamId" />
                <ReferenceInput label="Venue" source="venueId" reference="venue" perPage={1000} fullWidth={true} validate={[required()]}>
                    <SelectInput optionText={<VenueTextRenderer />} />
                </ReferenceInput>
                <BooleanInput label="Neutral Venue?" source="neutralVenue" />
            </SimpleForm>
        </Create>
    );
};