import * as React from "react";
import { 
    List, Show, Edit, Create, 
    Datagrid, Filter, SimpleShowLayout, SimpleForm, Pagination,
    TextField, BooleanField,
    TextInput, BooleanInput, RadioButtonGroupInput,
    required, useNotify, useRedirect, useShowController
} from 'react-admin';
import DateTimeField from '../components/DateTimeField';

const RefereeFilter = props => (
    <Filter {...props}>
        <RadioButtonGroupInput label="Status" source="isActive" choices={[
            { id: null, name: 'All' },
            { id: true, name: 'Active' },
            { id: false, name: 'Inactive' }
        ]} alwaysOn />
    </Filter>
);

export const RefereeList = props => {
    const ListPagination = (props) => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

    return (
        <List {...props} bulkActionButtons={false} filters={<RefereeFilter />} filterDefaultValues={{ isActive: null }} pagination={<ListPagination />} perPage={10}>
            <Datagrid rowClick="show">
                <TextField source="firstName" label="First Name" />
                <TextField source="lastName" label="Last Name" />
                <BooleanField source="isActive" label="Active?" />
            </Datagrid>
        </List>
    );
};
export const RefereeShow = props => {
    const { loading } = useShowController(props);
    if (loading) return null;

    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="id" label="ID" />
                <TextField source="firstName" label="First Name" />
                <TextField source="lastName" label="Last Name" />
                <BooleanField source="isActive" label="Active?" />
                <DateTimeField source="createdUtc" label="Created" />
                <DateTimeField source="lastUpdatedUtc" label="Last Updated" />
            </SimpleShowLayout>
        </Show>
    );
};

export const RefereeEdit = props => (
    <Edit {...props} mutationMode="optimistic">
        <SimpleForm>
            <TextInput source="id" label="ID" fullWidth={true} disabled />
            <TextInput source="firstName" label="First Name" fullWidth={true} validate={[required()]} />
            <TextInput source="lastName" label="Last Name" fullWidth={true} validate={[required()]} />
            <BooleanInput source="isActive" label="Active?" validate={[required()]} />
        </SimpleForm>
    </Edit>
);

export const RefereeCreate = props => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = ({ data }) => {
		notify(`Referee '${data.firstName} ${data.lastName}' successfully created.`);
		redirect(`/referee/${data.id}/show`);
	};

    return (
        <Create onSuccess={onSuccess} {...props}>
            <SimpleForm>
                <TextInput source="firstName" label="First Name" fullWidth={true} validate={[required()]} />
                <TextInput source="lastName" label="Last Name" fullWidth={true} validate={[required()]} />
            </SimpleForm>
        </Create>
    );
};