import * as React from "react";
import { 
    List, Show, Edit, Create, 
    Datagrid, SimpleShowLayout, SimpleForm, 
    TextField, TextInput, NumberInput,
    required, useNotify, useRedirect
} from 'react-admin';
import DateTimeField from '../components/DateTimeField';

export const SeasonList = props => (
    <List {...props} bulkActionButtons={false}>
        <Datagrid rowClick="show">
            <TextField source="year" label="Year" />
        </Datagrid>
    </List>
);

export const SeasonShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" label="ID" />
            <TextField source="year" label="Year" />
            <DateTimeField source="createdUtc" label="Created" />
            <DateTimeField source="lastUpdatedUtc" label="Last Updated" />
        </SimpleShowLayout>
    </Show>
);

export const SeasonEdit = props => (
    <Edit {...props} mutationMode="optimistic">
        <SimpleForm>
            <TextInput source="id" label="ID" fullWidth={true} disabled />
            <NumberInput source="year" label="Year" min="1908" fullWidth={true} validate={[required()]} />
        </SimpleForm>
    </Edit>
);

export const SeasonCreate = props => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = ({ data }) => {
		notify(`Season '${data.year}' successfully created.`);
		redirect(`/season/${data.id}/show`);
	};

    return (
        <Create onSuccess={onSuccess} {...props}>
            <SimpleForm>
                <NumberInput source="year" label="Year" min="1908" fullWidth={true} validate={[required()]} />
            </SimpleForm>
        </Create>
    );
};